// withPermissions.js
import React from "react";
import { useApiKey } from "./contexts/ApiKeyContext";

const withPermissions = (Component, requiredPermissions) => {
  return (props) => {
    const { apiKey, userPermissions, userEvents } = useApiKey();

    if(!userPermissions || userPermissions.length === 0) {
      return null;
    }
    // Check if the user has all required permissions
    const hasRequiredPermissions = requiredPermissions.every((permission) =>
      userPermissions.includes(permission)
    );

    if (hasRequiredPermissions) {
      return <Component {...props} />;
    } else {
      // Return null or an alternative component if the user does not have the required permissions
      return null;
    }
  };
};

export default withPermissions;
