import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useMemo } from 'react';
import styles from './Reports/DailyReport.module.css';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useApiKey } from "./contexts/ApiKeyContext";
import ApiService from './Api/ApiService';
import withPermissions from "./withPermissions";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    ArcElement
  );

  const DailyReportPermissions=[  "dailyReport.view",]

const DailyReport = () => {
  const { apiKey, userPermissions, userEvents } = useApiKey();
  
  const navigate = useNavigate();
  const [dailyData, setDailyData] = useState([]);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  
  const [startDate, setStartDate] = useState(today.toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState(tomorrow.toISOString().split("T")[0]);

  const [chartType, setChartType] = useState("bar");
  const [selectedDataType, setSelectedDataType] = useState('quantities');


  const canViewInternalFields = userEvents.includes(-1);

  const apiClient = ApiService();

  const handleDataTypeChange = (e) => {
    setSelectedDataType(e.target.value);
  };

  const renderChart = () => {
    const chartProps = {
      data: chartData,
      options: chartOptions,
      className: styles.chart,
    };

    const chartHorizontalProps = {
        data: chartData,
        options: chartHorizontalOptions,
        className: styles.chart,
      };

    switch (chartType) {
      case "horizontalBar":
        return <Bar {...chartHorizontalProps} />;
      case "line":
        return <Line {...chartProps} />;
      case "pie":
        return <Pie {...chartProps} />;
      case "bar":
      default:
        return <Bar {...chartProps} />;
    }
  };


  useEffect(() => {
    if (startDate && endDate) {
      fetchDailyData();
    }
  }, [startDate, endDate]);

  const fetchDailyData = async () => {
    try {

      const response = await apiClient.get(`/v1/daily-sales-report?start_date=${startDate}&end_date=${endDate}`);

      if(!userEvents.includes(-1))
      {
        // Filter events based on eventNumbers
        const filteredEvents = response.data.filter((event) =>
        userEvents.includes(event.event_number)
        );
        
        setDailyData(filteredEvents);
        return;
      }

      setDailyData(response.data);
    } catch (err) {
      console.error('Error fetching daily report data:', err.message);
    }
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const getRandomColor = () => {
    const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const r = getRandomInt(0, 255);
    const g = getRandomInt(0, 255);
    const b = getRandomInt(0, 255);
    return `rgba(${r}, ${g}, ${b}, 0.6)`;
  };

  const prepareChartData = (dailyData) => {
    const datasets = {};
    const labels = new Set();
  
    dailyData.forEach((item) => {
      labels.add(item.date);
    });
  
    dailyData.forEach((item) => {
      if (!datasets[item.event_name]) {
        const backgroundColor = getRandomColor();
        datasets[item.event_name] = {
          label: item.event_name,
          data: Array.from(labels).map((label) => (label === item.date ? item[selectedDataType] : 0)),
          backgroundColor,
          borderColor: backgroundColor.replace('0.6', '1'),
          borderWidth: 1,
        };
      } else {
        const index = Array.from(labels).indexOf(item.date);
        if (index !== -1) {
          datasets[item.event_name].data[index] = item[selectedDataType];
        }
      }
    });
  
    return {
      labels: Array.from(labels),
      datasets: Object.values(datasets),
    };
  };
  

  const chartData = useMemo(() => {
    return prepareChartData(dailyData, selectedDataType);
  }, [dailyData, selectedDataType]);

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 90,
          minRotation: 45,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle : true,
            pointStyle: 'circle',
          }
        },
    },
  };

  const chartHorizontalOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 45,
        },
      },
    },
  };

  const handleBackButtonClick = () => {
    // Navigate back to the App page
    navigate(-1);
  };

  return (
    <div className={styles.container}>
    {/* <IconButton
        onClick={handleBackButtonClick}
        color="primary"
        sx={{
          position: 'absolute',
          left: 8,
          top: 8,
        }}
      >
      <ArrowBackIcon />
      </IconButton> */}
      <h1 className={styles.title}>Daily Sales Report</h1>
      <div className={styles.dateRangeContainer}>
        <label htmlFor="start-date">Start Date:</label>
        <input
          type="date"
          id="start-date"
          value={startDate}
          onChange={handleStartDateChange}
          className={styles.dateInput}
        />
        <label htmlFor="end-date">End Date:</label>
        <input
          type="date"
          id="end-date"
          value={endDate}
          onChange={handleEndDateChange}
          className={styles.dateInput}
        />
      </div>

      <div className={styles.chartTypeSelectorContainer}>
      <label htmlFor="data-type">Data Type:</label>
        <select
        className={styles.dateInput}
        value={selectedDataType}
        onChange={handleDataTypeChange}
        >
        {canViewInternalFields && (<> <option value="total">Total</option> </>)}
        <option value="total_net">Total Net</option>
        <option value="quantities">Quantities</option>
        </select>
        <label htmlFor="chartType" className={styles.chartTypeLabel}>Chart Type:</label>
        <select
          value={chartType}
          onChange={(e) => setChartType(e.target.value)}
          className={styles.dateInput}
        >
          <option value="bar">Vertical Bar Chart</option>
          <option value="horizontalBar">Horizontal Bar Chart</option>
          <option value="line">Line Chart</option>
          <option value="pie">Pie Chart</option>
        </select>
      </div>
      <div className={styles.chartContainer}>{renderChart()}</div>
    </div>
  );
};

export default withPermissions(DailyReport, DailyReportPermissions);