import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, TextField, Box, Grid, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import withPermissions from "./withPermissions";
import "./Reports/Report.css";
import OrderDetails from "./Orders/OrderDetails";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventSelector from "./Events/EventSelector";
import { useApiKey } from "./contexts/ApiKeyContext";
import ApiService from './Api/ApiService';
import InputAdornment from '@mui/material/InputAdornment';
import { LocalizationProvider, DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Link from '@mui/material/Link';

const dataGridTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#f5f5f5",
        },
        columnHeader: {
          backgroundColor: "#3f51b5",
          color: "white",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#283593",
          },
        },
        cell: {
          "&:nth-of-type(odd)": {
            backgroundColor: "#e8eaf6",
          },
          "&:nth-of-type(even)": {
            backgroundColor: "#c5cae9",
          },
        },
      },
    },
  },
});

// Create a custom theme
const customTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.6)', // Change the text color of the disabled TextField
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
});

const EventsPanelPermissions = ["events.edit"]

function EventsPanel(props) {
  const [venue, setVenue] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [convenienceFee, setConvenienceFee] = useState("");
  const [serviceCharge, setServiceCharge] = useState("");

  const [paymentSources, setPaymentSources] = useState([]);
  const [selectedPaymentSource, setSelectedPaymentSource] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventTelInfo, setEventTelInfo] = useState("");
  const [eventContactEmail, setEventContactEmail] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventUniqueID, setEventUniqueID] = useState("");
  const [selectedEventNumber, setSelectedEventNumber] = useState("");
  const [selectedEventName, setSelectedEventName] = useState("");
  const [maxTickets, setMaxTickets] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventtData, setEventData] = useState({});
  const [isEventActive, setIsEventActive] = useState(false);
  const [eventDetailData, setEventDetailData] = useState({});
  const [venues, setVenues] = useState([]);

  const [selectedVenueNumber, setSelectedVenueNumber] = useState("");
  const [codereadrServiceNumber, setCodereadrServiceNumber] = useState("");
  const [showActiveEvents, setShowActiveEvents] = useState(true);
  const { apiKey, userPermissions, userEvents } = useApiKey();
  const [message, setMessage] = useState({ type: "", text: "" });

  const apiClient = ApiService();

  const handleShowActiveEventsChange = (e) => {
    setShowActiveEvents(e.target.checked);
  };

  const handleSetEventStatusChange = async (e) => {
    setIsEventActive(e.target.checked);

    try {
      const data = {
        event_active: e.target.checked ? 'yes' : 'no',
        event_number: selectedEventNumber
      }

      const response = await apiClient.put(`/v1/event`, data);

      if (response.status === 200) {
        console.log('Upload Scanned, successfully.');
        setMessage({ type: "success", text: "Event Status updated, successfully." });

      } else {
        setMessage({ type: "error", text: response.data.message });
      }
    }
    catch (error) {
      console.error('Error Sending emails!', error);
      setMessage({ type: "error", text: "Event Status > update failed." });
    }
  };

  const canEditOrder = true;

  function MakeCurrency(val) {
    if (!val) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0.00)
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val.toFixed(2))
  }

  const handleEventChange = async (e) => {
    setSelectedEventNumber(e.target.value);


    try {
      const response = await apiClient.get(`/v1/event?event_number=${e.target.value}`);
      const eventData = response.data[0];
      setEventDetailData(eventData);
      setSelectedVenueNumber(eventData.Venue_number);
      setSelectedPaymentSource(eventData.PaymentSourceId);
      setSelectedEventName(eventData.event_name);
      setEventDescription(eventData.event_description);
      setEventDate(eventData.event_date);
      setEventTime(eventData.event_time);
      setEventTelInfo(eventData.event_contact_phone);
      setEventContactEmail(eventData.event_contact_email)
      setEventUniqueID(eventData.uniqueID)
      setIsEventActive(eventData.event_active === 'yes' ? true : false);
      setCodereadrServiceNumber(eventData.cdr_service_id);
      setMaxTickets(eventData.max_tickets);
      setEventLink(`https://persiantix.com/wp-admin/post.php?post=${eventData.URI}&action=edit&classic-editor`);

      const selectedVenueData = venues.find((venue) => venue.Venue_number === eventData.Venue_number);
      setEventLocation(`${selectedVenueData.Venue_city}, ${selectedVenueData.Venue_state}, ${selectedVenueData.Venue_country}`);

      const eventPrices_response = await apiClient.get(`/v1/event/chargefee?event_number=${e.target.value}`);
      const pricesArray = eventPrices_response.data;

      // Join the prices with ',' and set the result as the price state
      const pricesString = pricesArray.map(priceObj => priceObj.Price).join(',');
      setPrice(pricesString);

      setServiceCharge(pricesArray[0].service_charge_percentage);
      setCurrency(pricesArray[0].currency);
      setConvenienceFee(pricesArray[0].tkt_conv_fee);

    } catch (err) {
      console.error("Error fetching event data:", err.message);
    }
  };

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const response = await apiClient.get(`/v1/venues`);
        setVenues(response.data);
      } catch (err) {
        console.error("Error fetching venue data:", err.message);
      }
    };
    const fetchPaymentSources = async () => {
      try {
        const response = await apiClient.get(`/v1/paymentsources`);
        setPaymentSources(response.data);
      } catch (err) {
        console.error("Error fetching payment sources data:", err.message);
      }
    };

    fetchVenues();
    fetchPaymentSources();
  }, []);

  function timeStringToDate(dateString, timeString) {
    if (!dateString || !timeString) {
      return null;
    }

    const date = new Date(`${dateString} ${timeString}`);
    // const [hours, minutes, seconds] = timeString.split(':').map(Number);
    // date.setHours(hours, minutes, seconds, 0);

    // Check if the date object is valid
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date or time string');
    }

    return date;
  }


  function dateToTimeString(date) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  const saveData = async () => {
    try {
      const eventData = {
        event_unique: eventUniqueID.toString(),
        event_ServCharge: serviceCharge.toString(),
        event_venue_name: selectedVenueNumber.toString(),
        ev_price: price,
        max_tickets: maxTickets.toString(),
        payment_source_name: selectedPaymentSource.toString(),
        event_Currency: currency,
        event_ConvFee: convenienceFee.toString(),
        event_tstart: eventTime,
        ev_tel_info: eventTelInfo,
        ev_email: eventContactEmail,
        ev_description: eventDescription,
        post_ID: eventDetailData.URI,
        event_admission: eventDetailData.has_seat,
        event_date_interval: eventDate,
        event_active: isEventActive ? 'yes' : 'no',
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': apiKey,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
      };

      const response = await axios.post(`https://api.persiantix.com/event/v1/upsert`, eventData, { headers: headers });

      if (response.status === 200) {
        console.log('Upload Scanned, successfully.');
        setMessage({ type: "success", text: "Event Updated, successfully." });

      } else {
        setMessage({ type: "error", text: response.data.message });
      }
    }
    catch (error) {
      console.error('Error Sending emails!', error);
      setMessage({ type: "error", text: "Event Update failed." });
    }
  };


  return (
    <div >
      <div className="content" style={{ paddingTop: '4px' }}>
        <div className="dashboard">

          {/* Render the events dropdown */}
          <h1>Events Panel</h1>
          <EventSelector
            showActiveEvents={showActiveEvents}
            handleShowActiveEventsChange={handleShowActiveEventsChange}
            handleEventChange={handleEventChange}
            userEvents={userEvents}
            userPermissions={userPermissions}
          />
          <div style={{ height: "100%", width: "100%" }}>
            {eventDetailData && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
                  <Box sx={{ display: 'flex' }}>
                    <FormControl sx={{ minWidth: 470, marginBottom: '1rem', marginRight: '1rem' }}>
                      <InputLabel id="venue-select-label">Venue</InputLabel>
                      <Select
                        labelId="venue-select-label"
                        id="venue-select"
                        label="Venue"
                        value={selectedVenueNumber}
                        onChange={(e) => {
                          setSelectedVenueNumber(e.target.value);
                          const selectedVenueData = venues.find((venue) => venue.Venue_number === e.target.value);
                          if (selectedVenueData) {
                            setEventLocation(`${selectedVenueData.Venue_city}, ${selectedVenueData.Venue_state}, ${selectedVenueData.Venue_country}`);
                          } else {
                            setEventLocation("");
                          }
                        }}
                      >
                        {venues.map((venue) => (
                          <MenuItem key={venue.Venue_number} value={venue.Venue_number}>
                            {venue.Venue_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      label="Venue Number"
                      value={selectedVenueNumber}
                      sx={{ marginBottom: "1rem", marginRight: "1rem", flexGrow: 1 }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>

                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      label="Event Name"
                      value={selectedEventName}
                      sx={{ marginBottom: "1rem", marginRight: "1rem", flexGrow: 1 }}
                      onChange={(e) => setSelectedEventName(e.target.value)}
                    />
                    <TextField
                      label="Event Location"
                      value={eventLocation}
                      sx={{ marginBottom: "1rem", marginRight: "1rem", flexGrow: 1 }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      label="Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                    />
                    <TextField
                      label="Max Tickets"
                      value={maxTickets}
                      onChange={(e) => setMaxTickets(e.target.value)}
                      sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <FormControl sx={{ marginBottom: '1rem', minWidth: 220, marginRight: '1rem' }}>
                      <InputLabel id="payment-source-label">Payment Source</InputLabel>
                      <Select
                        labelId="payment-source-label"
                        id="payment-source"
                        value={selectedPaymentSource}
                        label="Payment Source"
                        onChange={(e) => {
                          setPaymentSources(e.target.value);
                          // setEventDetailData({
                          //   ...eventDetailData,
                          //   PaymentSourceId: e.target.value,
                          // });
                        }}
                      >
                        {paymentSources.map((source) => (
                          <MenuItem key={source.PaymentSourceId} value={source.PaymentSourceId}>
                            {source.PaymentSource}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ marginBottom: '1rem', minWidth: 220, marginRight: '1rem' }}>
                      <InputLabel id="currency-label">Currency</InputLabel>
                      <Select
                        labelId="currency-label"
                        id="currency"
                        value={currency}
                        label="Currency"
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        {/* Add your currency options here */}
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="CAD">CAD</MenuItem>
                        <MenuItem value="GBP">GBP</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label="Convenience Fee"
                      value={MakeCurrency(convenienceFee)}
                      onChange={(e) => setConvenienceFee(e.target.value)}
                      sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                    />
                    <TextField
                      label="Service Charge"
                      value={serviceCharge}
                      onChange={(e) => setServiceCharge(e.target.value)}
                      sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />

                  </Box>

                  <Box sx={{ display: 'flex' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                        label="Event Date/Time"
                        value={timeStringToDate(eventDate, eventTime)}
                        onChange={(newValue) => {
                          setEventDate(newValue?.toISOString().substring(0, 10));
                          setEventTime(dateToTimeString(newValue))
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    <TextField
                      label="Event Tel Info"
                      value={eventTelInfo}
                      onChange={(e) => setEventTelInfo(e.target.value)}
                      sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                    />
                    <TextField
                      label="Event Contact Email"
                      value={eventContactEmail}
                      onChange={(e) => setEventContactEmail(e.target.value)}
                      sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      label="Unique ID"
                      value={eventUniqueID}
                      sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Event Number"
                      value={selectedEventNumber}
                      sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Codereader ID"
                      value={codereadrServiceNumber}
                      sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                  <TextField
                    label="Event Description"
                    value={eventDescription}
                    onChange={(e) => setEventDescription(e.target.value)}
                    // multiline
                    // rows={4}
                    sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                  />
                  <TextField
                    label="Edit Event Link"
                    value={eventLink}
                    sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      endAdornment: eventLink ? (
                        <Link href={eventLink} target="_blank" rel="noopener noreferrer">
                          Open link
                        </Link>
                      ) : null,
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveData}
                    sx={{ marginBottom: '1rem', marginRight: '1rem', flexGrow: 1 }}
                  >
                    Save
                  </Button>
                  <Box sx={{ display: 'flex' }}>

                    <div className="activeEventsContainer">
                      <label htmlFor="show-event-status" className="showActiveEventsLabel">
                        <span className="switch">
                          <input
                            type="checkbox"
                            name="show-event-status"
                            id="show-event-status"
                            sx={{ marginBottom: '1rem', marginRight: '1rem', minWidth: 420 }}
                            checked={isEventActive}
                            onChange={handleSetEventStatusChange}
                          />
                          <span className="slider round"></span>
                        </span>
                        Active Event
                      </label>
                    </div>
                  </Box>
                  {message.text && (
                    <p className={`message ${message.type}`}>
                      {message.text}
                    </p>
                  )}
                </Box>

              </>)}

          </div>
        </div>
      </div>
    </div >
  );
}

export default withPermissions(EventsPanel, EventsPanelPermissions);