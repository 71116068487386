import React, { useState, useEffect } from "react";
import "./SeatMapModal.css";
import withPermissions from "../withPermissions";
const SeatMapModalPermissions = [  
  "seatMapModal.view", 
  "seatMapModal.edit",];

const SeatMapModal = ({ visible, onButtonClick, onClose }) => {
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 100, y: 100 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [price, setPrice] = useState(0); // New state variable for price

  const handleMouseDown = (e) => {
    e.preventDefault();
    setDragging(true);
    setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({ x: e.clientX - offset.x, y: e.clientY - offset.y });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  if (!visible) {
    return null;
  }

  const buttonValues = ["On/Off", "In-store", "Wheelchair", "Tech"];

  return (
    <div className="modal">
      <div
        className="modal-content"
        style={{ left: position.x + "px", top: position.y + "px" }}
      >
        <div className="modal-header">
          <h3>Seat Options</h3>
          <button className="close-button" onClick={onClose}>
            X
          </button>
          <div className="draggable-area" onMouseDown={handleMouseDown}>
            <i className="fas fa-arrows-alt draggable-icon"></i>
          </div>
        </div>
        <div className="modal-price-input-container">
          <label htmlFor="price-input">Price:</label>
          <input
            id="price-input"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <button
            key="Price"
            className="modal-button"
            onClick={() => onButtonClick("Price", price)}
          >
            Set Price
          </button>
        </div>
        <div className="modal-button-container">
          {buttonValues.map((value) => (
            <button
              key={value}
              className="modal-button"
              onClick={() => onButtonClick(value, price)}
            >
              {value}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withPermissions(SeatMapModal, SeatMapModalPermissions);
