import { createContext, useContext, useState } from "react";

const ApiKeyContext = createContext();

const initialState = {
  isAuthenticated: false,
  apiKey: null,
  userPermissions: [],
  userEvents: [],
};

export const useApiKey = () => {
  const context = useContext(ApiKeyContext);
  if (!context) {
    throw new Error("useApiKey must be used within an ApiKeyProvider");
  }
  return context;
};

export const ApiKeyProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const setAuthenticated = (isAuthenticated) => {
    setState((prevState) => ({ ...prevState, isAuthenticated }));
  };

  const setApiKey = (apiKey) => {
    setState((prevState) => ({ ...prevState, apiKey }));
  };

  const setUserPermissions = (userPermissions) => {
    setState((prevState) => ({ ...prevState, userPermissions }));
  };

  const setUserEvents = (userEvents) => {
    setState((prevState) => ({ ...prevState, userEvents }));
  };

  return (
    <ApiKeyContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        setAuthenticated,
        apiKey: state.apiKey,
        setApiKey,
        userPermissions: state.userPermissions,
        setUserPermissions,
        userEvents: state.userEvents,
        setUserEvents,
      }}
    >
      {children}
    </ApiKeyContext.Provider>
  );
};
