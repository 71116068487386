import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import withPermissions from "./withPermissions";
import "./Reports/Report.css";
import OrderDetails from "./Orders/OrderDetails";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import EventSelector from "./Events/EventSelector";
import { useApiKey } from "./contexts/ApiKeyContext";
import ApiService from './Api/ApiService';
import Typography from "@mui/material/Typography";

const dataGridTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#f5f5f5",
        },
        columnHeader: {
          backgroundColor: "#3f51b5",
          color: "white",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#283593",
          },
        },
        cell: {
          "&:nth-of-type(odd)": {
            backgroundColor: "#e8eaf6",
          },
          "&:nth-of-type(even)": {
            backgroundColor: "#c5cae9",
          },
        },
      },
    },
  },
});

// Create a custom theme
const customTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.6)', // Change the text color of the disabled TextField
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
});

function MakeCurrency(val){
  if(!val) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0.00)
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val.toFixed(2))
}

// Custom footer component
function CustomFooter({ totalCount, grossPaySum, scannedSum, ourPortionSum, faceValuesSum, canViewInternalFields }) {
  return (
    <div className="customFooter">
      <div className="customFooterItem">Total Ticket: <span className="total-ticket">{totalCount}</span></div>
      {canViewInternalFields && (
        <><div className="customFooterItem">Gross Pay: <span className="currency">{MakeCurrency(grossPaySum)}</span></div></>)}
      <div className="customFooterItem">Face Values: <span className="currency">{MakeCurrency(faceValuesSum)}</span></div>
      {canViewInternalFields && (
        <><div className="customFooterItem">Persiantx Portion: <span className="currency-portion">{MakeCurrency(ourPortionSum)}</span></div></>)}
      {canViewInternalFields && (
        <><div className="customFooterItem">Scanned: <span className="scanned">{scannedSum}</span></div></>)}
    </div>
  );
}

const ReportsPanelPermissions = ["reports.view",]

function ReportsPanel(props) {
  // const userPermissions = props.userPermissions;
  // const userEvents = props.userEvents;
  const navigate = useNavigate();
  const [selectedEventNumber, setSelectedEventNumber] = useState(null);
  const [selectedEventName, setSelectedEventName] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [grossPaySum, setGrossPaySum] = useState(0);
  const [faceValuesSum, setFaceValuesSum] = useState(0);
  const [scannedSum, setScannedSum] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalOurPortionSum, setTotalOurPortionSum] = useState(0);
  const [showActiveEvents, setShowActiveEvents] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
  const { apiKey, userPermissions, userEvents } = useApiKey();

  const apiClient = ApiService();

  const canEditReport = userPermissions.includes("reports.edit");
  const canViewInternalFields = userEvents.includes(-1);

  const handleShowActiveEventsChange = (e) => {
    setShowActiveEvents(e.target.checked);
  };

  // Calculate the sum of the "Gross Pay" values whenever the report data changes
  useEffect(() => {
    const grossPay = reportData.reduce((acc, row) => acc + parseFloat(row.total_price), 0);
    const scanned = reportData.reduce((acc, row) => acc + parseInt(row.total_Scanned), 0);
    const totalTicket = reportData.reduce((acc, row) => acc + parseInt(row.quantity), 0);
    const totalOurPortion = reportData.reduce((acc, row) => acc + parseFloat(row.our_portion), 0);
    const faceValuesSumObg = reportData.reduce((acc, row) => acc + parseFloat(row.total_srv_free), 0);

    setGrossPaySum(grossPay);
    setScannedSum(scanned);
    setTotalCount(totalTicket);
    setTotalOurPortionSum(totalOurPortion);
    setFaceValuesSum(faceValuesSumObg);

  }, [reportData]);

  const fetchReportData = async () => {
    try {
      if (selectedEventNumber == null || selectedEventNumber <= 0) return;
      const response = await apiClient.get(`/v1/report?event_number=` + selectedEventNumber);

      const updatedData = response.data.map((item) => {
        // Assuming renderOurPortionCell takes the item as its argument
        const ourPortionValue = renderOurPortionCell(item);

        return {
          ...item,
          our_portion: ourPortionValue,
        };
      });

      setReportData(updatedData);
      setFilteredData(updatedData);

    } catch (err) {
      console.error("Error fetching report data:", err.message);
    }
  };

  // Fetch the report data for the selected event
  useEffect(() => {
    fetchReportData();
  }, [selectedEventNumber]);

  // Filter the report data whenever the search text changes
  useEffect(() => {
    const lowerCaseSearchText = searchText.toLowerCase();

    setFilteredData(
      reportData.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(lowerCaseSearchText)
        )
      )
    );
  }, [searchText, reportData]);

  // Calculate the sum of the "Our Portion" values 
  const renderOurPortionCell = (report) => {
    let mc_fee = 0;
    let our_portion = 0;

    if (report.currency === 'CAD') {
      if (report.payment_intent) {
        mc_fee = report.mc_fee === 0 ? report.total_price * 0.029 + 0.30 : report.mc_fee;
        our_portion = !report.is_complementary ? Math.max(0, report.total_price - mc_fee - report.total_srv_free) : report.total_price;
      } else if (1627790400 < report.unix_order_date && report.unix_order_date < 1609477200) {
        if (report.mc_fee <= 0) {
          mc_fee = report.paypal_residency && report.paypal_residency.trim() === 'US'
            ? report.total_price * 0.0349 + 0.59
            : report.total_price * 0.049 + 0.59;
        } else {
          mc_fee = report.mc_fee;
        }

        our_portion = !report.is_complementary ? Math.max(5, report.total_price - mc_fee - report.total_srv_free) : report.total_price;
      } else if (report.unix_order_date > 1609477200) {
        if (report.mc_fee <= 0) {
          mc_fee = report.paypal_residency && report.paypal_residency.trim() === 'US'
            ? report.total_price * 0.0349 + 0.59
            : report.total_price * 0.0499 + 0.59;
        } else {
          mc_fee = report.mc_fee;
        }

        our_portion = !report.is_complementary ? Math.max(5, report.total_price - mc_fee - report.total_srv_free) : report.total_price;
      } else {
        our_portion = Math.max(0, (report.total_price - ((report.total_price * 0.044) + 0.3)) - report.total_srv_free);
      }
    }

    if (report.currency === 'USD') {
      if (report.payment_intent) {
        mc_fee = report.mc_fee === 0 ? report.total_price * 0.029 + 0.30 : report.mc_fee;
        our_portion = !report.is_complementary ? Math.round(Math.max(0, report.total_price - mc_fee - report.total_srv_free), 2) : report.total_price;
      } else if (report.unix_order_date > 1627790400) {
        if (report.mc_fee <= 0) {
          mc_fee = report.total_price * 0.0349 + 0.49;
        } else {
          mc_fee = report.mc_fee;
        }

        our_portion = !report.is_complementary ? Math.max(5, report.total_price - mc_fee - report.total_srv_free) : report.total_price;
      } else {
        our_portion = Math.max(0, (report.total_price - ((report.total_price * 0.029) + 0.3)) - report.total_srv_free);
      }
    }

    if (report.currency === 'GBP') {
      our_portion = (report.total_price - ((report.total_price * 0.044) + 0.2)) - report.total_srv_free;
    }

    our_portion = parseFloat(our_portion.toFixed(2));
    report.our_portion = our_portion;
    return our_portion;
  };

  const extractCurrencyValue = (currencyStr) => {
    const parsedValue = parseFloat(currencyStr.replace(/[^0-9.-]+/g, ''));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  function MultiLineCellRenderer(props) {
    const { value } = props;
    const lines = value.split('\n').map((line, index) => <div key={index}>{line}</div>);
    return <>{lines}</>;
  }

  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const renderCellWithNewlines = (params) => {
    const colors = ['primary', 'secondary', 'error', 'textPrimary', 'textSecondary' ];
    
    return (
      <div style={{ whiteSpace: "pre-line" }}>
        {params.value.split('\n').map((line, index) => (
          <Typography key={index} color={colors[index % 5]}>
            {line}
          </Typography>
        ))}
      </div>
    );
  };

  //Report Data
  const hasSeat = (reportData && reportData.length > 0) ? reportData[0].has_seat : null;


  // Dynamic column definitions based on 'has_seat'
  let columns = [
    { field: "id", hide: true },
    {
      field: "name", headerName: "Name", minWidth: 150, flex: 1,
      valueGetter: (params) => `${params.row.bill_first_name} ${params.row.bill_last_name}`
    },
    {
      field: "order_number", headerName: "Order Number", minWidth: 150,
      sortComparator: (value1, value2) => value1.toString().localeCompare(value2.toString())
    },
    { field: "Levels", headerName: "Section", resizable: true, minWidth: 300, renderCell: renderCellWithNewlines},
  ];
  
  if (hasSeat === 1) {
    columns.push(
      { field: "Rows", headerName: "Row", minWidth: 50, renderCell: renderCellWithNewlines},
      { field: "Seats", headerName: "Seats", minWidth: 150, renderCell: renderCellWithNewlines }
    );
  } else if (hasSeat === 0) {
    columns.push({ field: "number_of_ticket", headerName: "Ticket Count", minWidth: 100, renderCell: renderCellWithNewlines});
  }
  
  columns.push(
    { field: "quantity", headerName: "Count", minWidth: 20 },
    {
      field: "total_srv_free", headerName: "Face Values", minWidth: 50,
      valueGetter: (params) => MakeCurrency(params.row.total_srv_free),
      sortComparator: (value1, value2) => {
        const numValue1 = extractCurrencyValue(value1);
        const numValue2 = extractCurrencyValue(value2);
        return numValue1 - numValue2;
      }
    },
    { field: "paypal_residency", headerName: "Residency", minWidth: 50 }
  );

  if (canViewInternalFields) {
    columns.push(
      { field: "email", headerName: "Email", minWidth: 220 },
      { field: "total_price", headerName: "Gross Pay", minWidth: 50,  valueGetter: (params) => 
      MakeCurrency(params.row.total_price), sortComparator: (value1, value2) => {
        const numValue1 = extractCurrencyValue(value1);
        const numValue2 = extractCurrencyValue(value2);
        return numValue1 - numValue2;
      }},
      { field: "our_portion", headerName: "Our Portion", minWidth: 50 , valueGetter: (params) => 
      MakeCurrency(params.row.our_portion), sortComparator: (value1, value2) => {
        const numValue1 = extractCurrencyValue(value1);
        const numValue2 = extractCurrencyValue(value2);
        return numValue1 - numValue2;
      }},
      { field: "mc_fee", headerName: "Paypal Fee", minWidth: 50 , valueGetter: (params) => 
      MakeCurrency(params.row.mc_fee), sortComparator: (value1, value2) => {
        const numValue1 = extractCurrencyValue(value1);
        const numValue2 = extractCurrencyValue(value2);
        return numValue1 - numValue2;
      }},
      { field: "total_Scanned", headerName: "Scanned", minWidth: 20 },
      { field: "phone", headerName: "Phone", minWidth: 130,valueGetter: (params) => 
      formatPhoneNumber(params.row.phone)},
      { field: "order_date", headerName: "Date", minWidth: 150 ,  valueFormatter: (params) => {
        const date = new Date(params.value);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
      }},);
  }

  const handleRowClick = (rowParams) => {
    //setSelectedRowData(rowParams.row);
    setSelectedOrderNumber(rowParams.row.order_number);
    setOpenDialog(true);
  };


  const handleEventChange = (e) => {
    setSelectedEventNumber(e.target.value);
    setSelectedEventName(e.target.options[e.target.selectedIndex].dataset.eventName);
  };

  const [cols, setCols] = useState(columns);
  const handleColumnResize = (colId, newWidth) => {
    setCols((prevCols) =>
      prevCols.map((col) => (col.field === colId ? { ...col, width: newWidth } : col))
    );
  };

  // Handle back button
  const handleBackButtonClick = () => {
    // Navigate back to the App page
    navigate(-1);
  };


  function GridButtons({ onSendAllEmails, onUploadScanned, onCreateUpdateCodereadr, onDownloadCSVReport, onRefresh }) {
    return (
      <div className="grid-buttons">
        <Button className="grid-button" onClick={onSendAllEmails}>Send All Emails</Button>
        <Button className="grid-button" onClick={onUploadScanned}>Upload Scanned</Button>
        <Button className="grid-button" onClick={onCreateUpdateCodereadr}>Create/Update Codereadr</Button>
        <Button className="grid-button" onClick={onDownloadCSVReport}>Download CSV Report</Button>
        <Button className="grid-button" onClick={onRefresh}>Refresh</Button>
      </div>
    );
  }

  // Handle send all emails button
  const handleSendAllEmails = async () => {
    try {

      // Check if the selectedRows array is empty
      if (!selectedRows || selectedRows.length === 0) {
        return;
      }

      // Use the map function to create the order_numbers array
      const order_numbers = selectedRows.map((row) => row.order_number);

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': apiKey,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
      };

      const data = {
        order_numbers: parseInt(order_numbers)
      }
      const response = await axios.post(`https://api.persiantix.com/ticket/v1/email`, data, { headers: headers });

      if (response.status === 200) {
        console.log('Sending emails, successfully.');
      } else {
        alert("Error Sending emails!");
      }
    }
    catch (error) {
      console.error('Error Sending emails!', error);
      alert("Error Sending emails!");
    }
  }

  const handleUploadScanned = async () => {
    try {

      // Check if the selectedEventNumber is empty
      if (!selectedEventNumber || parseInt(selectedEventNumber) === 0) {
        return;
      }

      fetchReportData();
      
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': apiKey,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
      };

      const data = {
        event: parseInt(selectedEventNumber)
      }
      const response = await axios.post(`https://api.persiantix.com/codereadr/v1/scanned`, data, { headers: headers });

      if (response.status === 200) {
        console.log('Upload Scanned, successfully.');
        alert("Scans uploaded!");
      } else {
        alert("Uploading Scanned Failed!");
      }
    }
    catch (error) {
      console.error('Error Sending emails!', error);
      alert("Uploading Scanned Failed!");
    }
  }

  const handleCreateUpdateCodereadr = async () => {
    try {

      // Check if the selectedEventNumber is empty
      if (!selectedEventNumber || parseInt(selectedEventNumber) === 0 || !selectedEventName) {
        return;
      }

      fetchReportData();

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': apiKey,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
      };

      const data = {
        event: parseInt(selectedEventNumber),
        event_name: selectedEventName,
      }
      const response = await axios.post(`https://api.persiantix.com/codereadr/v1/create`, data, { headers: headers });

      if (response.status === 200) {
        console.log('Codereadr Created/Updated!');
        //alert("Codereadr Created/Updated!");
        alert(atob(response.data['message']));
      } else {
        alert("Uploading Scanned Failed!");
      }
    }
    catch (error) {
      console.error('Error Sending emails!', error);
      alert("Create/Update Codereadr Failed!");
    }
  }

  const handleDownloadCSVReport = async () => {
    try {
      // Check if the selectedEventNumber is empty
      if (!selectedEventNumber || parseInt(selectedEventNumber) === 0) {
        return;
      }

      fetchReportData();

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': apiKey,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
      };

      const data = {
        event: parseInt(selectedEventNumber)
      };

      const response = await axios.post(`https://api.persiantix.com/report/v1/csvreport`, data, { headers: headers });

      if (response.status === 200) {

        const textDecoder = new TextDecoder();
        const filename = textDecoder.decode(Uint8Array.from(atob(response.data['filename']), c => c.charCodeAt(0)));
        const byteCharacters = textDecoder.decode(Uint8Array.from(atob(response.data['message']), c => c.charCodeAt(0)));

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'text/csv' });

        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
        a.remove();

        console.log('DownloadCSVReport, successfully.');
      } else {
        alert("DownloadCSVReport Failed!");
      }
    } catch (error) {
      console.error('Error DownloadCSVReport!', error);
      alert("DownloadCSVReport Failed!");
    }
  };

  function handleRefresh() {
    fetchReportData();
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getTogglableColumns = (columns) => {
    // hide the column with field `id` from list of togglable columns
    return columns
      .filter((column) => column.field !== 'id')
      .map((column) => column.field);
  };

  return (
    <div >
      {/* <div style={{ display: 'flex', marginTop: '10px', marginBottom: '30px' }}>
        <IconButton
          onClick={handleBackButtonClick}
          color="primary"
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      </div> */}
      <div  >
        <div className="dashboard">

          {/* Render the events dropdown */}
          <h1>Reports Panel</h1>
          <EventSelector
            showActiveEvents={showActiveEvents}
            handleShowActiveEventsChange={handleShowActiveEventsChange}
            handleEventChange={handleEventChange}
            userEvents={userEvents}
            userPermissions={userPermissions}
          />
          <TextField
            label="Filter"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputLabelProps={{
              className: 'mui-filter-label',
            }}
            InputProps={{
              className: 'mui-filter-input',
              disableunderline: true,
              classes: {
                focused: 'mui-filter-input',
              },
            }}
          />
          <div style={{ height: "100%", width: "100%" }}>
            <ThemeProvider theme={dataGridTheme}>
              {/* Render the custom footer */}
              <CustomFooter
                totalCount={totalCount}
                grossPaySum={grossPaySum}
                scannedSum={scannedSum}
                ourPortionSum={totalOurPortionSum}
                faceValuesSum={faceValuesSum}
                canViewInternalFields={canViewInternalFields}
              />
              <DataGrid
                columnVisibilityModel={{
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  //paypal_residency: false,
                }}
                getRowHeight={() => 'auto'} getEstimatedRowHeight={() => 200} 
                rows={filteredData}
                columns={columns}
                pageSize={filteredData.length}
                rowsPerPageOptions={[100, 300, 500]}
                checkboxSelection={canEditReport}
                selectionModel={selectedRows}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectedRows(newSelectionModel);
                }}
                disableRowSelectionOnClick
                {...(canEditReport
                  ? {
                      slots: {
                        toolbar: GridToolbar,
                      },
                    }
                  : {})}

                onRowClick={(params) => handleRowClick(params)}
                //hideFooterPagination
                //autoHeight
                onColumnResizeCommitted={(params) =>
                  handleColumnResize(params.field, params.element.clientWidth)
                }
              />
              {canEditReport && (<><GridButtons
                onSendAllEmails={canEditReport ? handleSendAllEmails : ''}
                onUploadScanned={canEditReport ? handleUploadScanned : ''}
                onCreateUpdateCodereadr={canEditReport ? handleCreateUpdateCodereadr : ''}
                onDownloadCSVReport={canEditReport ? handleDownloadCSVReport : ''}
                onRefresh={canEditReport ? handleRefresh : ''}
              /></>)}

            </ThemeProvider>
            {selectedOrderNumber && (
              <OrderDetails
                order_number={selectedOrderNumber}
                handleCloseDialog={handleCloseDialog}
                openDialog={openDialog}
                userPermissions={userPermissions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withPermissions(ReportsPanel, ReportsPanelPermissions);