import React, { useState, useEffect } from "react";
import axios from "axios";

import withPermissions from "../withPermissions";
import { useApiKey } from "../contexts/ApiKeyContext";
import ApiService from '../Api/ApiService';

const EventSelectorPermissions = ["events.view"];

function EventSelector({
  showActiveEvents,
  handleShowActiveEventsChange,
  handleEventChange
}) {
  const [events, setEvents] = useState([]);
  const { apiKey, userPermissions, userEvents } = useApiKey();

  const apiClient = ApiService();

  // Fetch the list of events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if(!userEvents || userEvents.length === 0) {
          return;
        }

        const response = await apiClient.get(`/v1/events`);

        if(!userEvents.includes(-1))
        {
          // Filter events based on eventNumbers
          const filteredEvents = response.data.filter((event) =>
          userEvents.includes(event.event_number)
          );
          setEvents(filteredEvents);
          return;
        }

        setEvents(response.data);

      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEvents();
  }, []);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  // Helper function to check if an event is active
  const isEventActive = (event) => {
    const currentDate = new Date();
    //const eventDate = new Date(event.event_date);
    return event.event_date >= formatDate(currentDate);
  };

  // Filter events based on the user's choice
  const filteredEvents = showActiveEvents ? events.filter(isEventActive) : events;

  return (
    <div className="radio-buttons-box">
      <div className="radio-buttons-container">
        <div className="eventSelectorContainer">
          <div className="activeEventsContainer">
            <label htmlFor="show-active-events" className="showActiveEventsLabel">
              <span className="switch">
                <input
                  type="checkbox"
                  name="show-active-events"
                  id="show-active-events"
                  checked={showActiveEvents}
                  onChange={handleShowActiveEventsChange}
                />
                <span className="slider round"></span>
              </span>
              Show Active Events Only
            </label>
          </div>
          <div className="selectEventContainer">
            <label htmlFor="event-selector" className="eventSelectorLabel">
              Select Event:
            </label>
            <select
              name="event-selector"
              id="event-selector"
              className="eventSelector"
              onChange={handleEventChange}
            >
              <option value="">-- Select an event --</option>
              {filteredEvents.map((event) => (
                <option
                  key={event.event_number}
                  value={event.event_number}
                  data-event-name={`${event.event_name} - ${event.event_date}`}
                >
                  {event.event_name} - {event.event_date}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withPermissions(EventSelector, EventSelectorPermissions);