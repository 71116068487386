import React from "react";

import Button from "@mui/material/Button";
import "./Dashboard/Dashboard.css";
import { useNavigate } from 'react-router';
import withPermissions from "./withPermissions";
import { useApiKey } from "./contexts/ApiKeyContext";
import ApiService from './Api/ApiService';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />
  }
  return Wrapper;
}

const DashboardPermissions = []

function Dashboard(props) {

  const { apiKey, userPermissions, userEvents } = useApiKey();

  const handleButtonClick = (path) => {
    props.history(path);
  };

  return (
    <div className="dashboard">
      <div className="dashboard-buttons">
        {userPermissions.includes("orders.view_all") ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => handleButtonClick("/orders")}
          >
            Orders
          </Button>
        ) : null}
        {userPermissions.includes("reports.view") ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => handleButtonClick("/reports")}
          >
            Event Reports
          </Button>
        ) : null}
        {userPermissions.includes("seatingChart.view") ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => handleButtonClick("/seating-charts")}
          >
            Seating Charts
          </Button>
        ) : null}
        {userPermissions.includes("dailyReport.view") ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => handleButtonClick("/daily-report")}
          >
            Daily Report
          </Button>
        ) : null}
          {userPermissions.includes("events.edit") ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => handleButtonClick("/events")}
          >
            Events
          </Button>
        ) : null}
          {userPermissions.includes("users.edit") ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => handleButtonClick("/users")}
          >
            Users
          </Button>
        ) : null}

      </div>
    </div>
  );
}

export default withRouter(Dashboard);
