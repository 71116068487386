import React from 'react';
import axios from 'axios';
import { useApiKey } from "../contexts/ApiKeyContext";

function ApiService() {
  const { apiKey, userPermissions, userEvents } = useApiKey();

  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${apiKey}`,
      permissions: userPermissions,
      events: userEvents,
    },
  });

  return apiClient;
}

export default ApiService;
