import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Box, Grid, Typography } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Reports/Report.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { useApiKey } from "../contexts/ApiKeyContext";
import { styled } from '@mui/system';
import withPermissions from "../withPermissions";
import ApiService from '../Api/ApiService';
import InputAdornment from '@mui/material/InputAdornment';

const dataGridTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#f5f5f5",
        },
        columnHeader: {
          backgroundColor: "#3f51b5",
          color: "white",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#283593",
          },
        },
        cell: {
          "&:nth-of-type(odd)": {
            backgroundColor: "#e8eaf6",
          },
          "&:nth-of-type(even)": {
            backgroundColor: "#c5cae9",
          },
        },
      },
    },
  },
});

// Create a custom theme
const customTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.6)', // Change the text color of the disabled TextField
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
});

const OrderDetailsPermissions = ["orders.view"];

const OrderDetails = ({ order_number, handleCloseDialog, openDialog }) => {
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
  const [selectedEventNumber, setSelectedEventNumber] = useState(null);
  // const [openDialog, setOpenDialog] = useState(true);
  const [rowDetails, setRowDetails] = useState([]);
  const [levelRowSeat, setLevelRowSeat] = useState({});
  const [isAnyRowChanged, setIsAnyRowChanged] = useState({});
  const [faceValueStatus, setFaceValueStatus] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameStatus, setFirstNameStatus] = useState("idle");
  const [lastNameStatus, setLastNameStatus] = useState("idle");
  const [paypalInfoPopup, setPaypalInfoPopup] = useState(false);
  const [canEditOrder, setCanEditOrder] = useState(false);
  const [paypalInfoData, setPaypalInfoData] = useState([]);
  const [summary, setSummary] = useState({
    totalPrice: 0,
    totalServiceCharge: 0,
    totalRows: 0,
  });

  const { apiKey, userPermissions, userEvents } = useApiKey();

  const apiClient = ApiService();

  const canViewInternalFields = userEvents.includes(-1);

  // Fetch Order Details
  useEffect(() => {
    if (!order_number) {
      return;
    }
    const fetchOrderData = async () => {
      try {

        const response = await apiClient.get(`/v1/order/details?order_number=${order_number}`);

        if (response && response.data && response.data.length > 0) {

          const levelrowseat = {};

          if (!userEvents || userEvents.length === 0 || (!userEvents.includes(response.data[0].event_number) && !userEvents.includes(-1))) {
            return;
          }

          userEvents.includes(response.data[0].event_number)
          const event_response = await apiClient.get(`/v1/event/levelrowseat?event_number=${response.data[0].event_number}`);
          for (const data of event_response.data) {

            if (!levelrowseat[data.Level]) {
              levelrowseat[data.Level] = {};
            }
            if (!levelrowseat[data.Level][data.Row]) {
              levelrowseat[data.Level][data.Row] = {};
            }
            levelrowseat[data.Level][data.Row] = data.Seats.split(",").map((seat) => seat.trim());
          }

          setLevelRowSeat(levelrowseat);
          setRowDetails(response.data);
          setSelectedEventNumber(response.data[0].event_number);
          setSelectedOrderNumber(order_number);
          setCanEditOrder(response.data[0].submitted != "refunded" & userPermissions.includes("orders.edit"));
        }
        else {
          setLevelRowSeat({});
          setRowDetails([]);
          setSelectedEventNumber(null);
          setSelectedOrderNumber(null);
        }

      } catch (err) {
        console.error("Error fetching fetchOrderData data:", err.message);
      }
    };

    fetchOrderData();
    //setOpenDialog(true);

  }, [order_number]);

  // Fetch Order Details
  const fetchRowDetails = async () => {
    try {

      if (!selectedOrderNumber) return;

      const response = await apiClient.get(`/v1/order/details?order_number=${selectedOrderNumber}`);
      setRowDetails(response.data);
      setSelectedEventNumber(response.data[0].event_number);
      setCanEditOrder(response.data[0].submitted != "refunded" & userPermissions.includes("orders.edit"));

    } catch (err) {
      console.error("Error fetching fetchRowDetails data:", err.message);
    }
  };

  // Handle Grid Summary
  useEffect(() => {
    let totalPrice = 0;
    let totalServiceCharge = 0;

    rowDetails.forEach((row) => {
      totalPrice += parseFloat(row.Price || 0);
      totalServiceCharge += parseFloat(row.service_charge || 0);
    });

    setSummary({
      totalPrice,
      totalServiceCharge,
      totalRows: rowDetails.length,
    });
  }, [rowDetails]);

  // Set Level 
  const renderLevelCell = (params) => {

    const handleChange = async (event, params) => {
      const selectedLevel = event.target.value;

      // Find the index of the row with the same id as the row being updated
      const rowIndex = rowDetails.findIndex((row) => row.id === params.id);
      // Update the row with the new level value
      const updatedRowDetails = [...rowDetails];
      updatedRowDetails[rowIndex] = {
        ...updatedRowDetails[rowIndex],
        Level: selectedLevel,
        Row: "", // Reset the Row value when Level changes
      };
      setRowDetails(updatedRowDetails);
    };

    const levels = Object.keys(levelRowSeat) || [];

    return (
      <Select
        value={params.value}
        onChange={(event) => handleChange(event, params)}
        disabled={!canEditOrder}
      >
        {levels.map((level) => (
          <MenuItem key={level} value={level}>
            {level}
          </MenuItem>
        ))}
      </Select>
    );
  };

  // Set Row
  const renderRowCell = (params) => {

    const handleChange = async (event, params) => {
      const selectedRow = event.target.value;

      // Find the index of the row with the same id as the row being updated
      const rowIndex = rowDetails.findIndex((row) => row.id === params.id);
      // Update the row with the new level value
      const updatedRowDetails = [...rowDetails];
      updatedRowDetails[rowIndex] = {
        ...updatedRowDetails[rowIndex],
        Row: selectedRow, // Reset the Row value when Level changes
        SeatNumber: "", // Reset the SeatNumber value when Row changes
      };
      setRowDetails(updatedRowDetails);
    };

    const rowsForLevel = Object.keys(levelRowSeat[params.row.Level]) || [];

    return (
      <Select
        value={params.value}
        onChange={(event) => handleChange(event, params)}
        disabled={!canEditOrder}
      >
        {rowsForLevel.map((row) => (
          <MenuItem key={row} value={row}>
            {row}
          </MenuItem>
        ))}
      </Select>
    );
  };

  // Set Seat
  const renderSeatCell = (params) => {
    const handleChange = async (event, params) => {
      const selectedSeat = event.target.value;

      // Find the index of the row with the same id as the row being updated
      const rowIndex = rowDetails.findIndex((row) => row.id === params.id);
      // Update the row with the new level value
      const updatedRowDetails = [...rowDetails];
      updatedRowDetails[rowIndex] = {
        ...updatedRowDetails[rowIndex],
        SeatNumber: selectedSeat, // Reset the SeatNumber value when Row changes
      };
      setRowDetails(updatedRowDetails);
    };

    const seatsForRow = levelRowSeat[params.row.Level][params.row.Row] || [];

    return (
      <Select
        value={params.value}
        onChange={(event) => handleChange(event, params)}
        disabled={!canEditOrder}
      >
        {seatsForRow.map((seat) => (
          <MenuItem key={seat} value={seat}>
            {seat}
          </MenuItem>
        ))}
      </Select>
    );
  };

  // Render Status
  const renderStatusCell = (params) => {
    const { initialLevel, initialRow, initialSeat, Level, Row, SeatNumber } = params.row;

    const levelChanged = initialLevel !== Level;
    const rowChanged = initialRow !== Row;
    const seatChanged = initialSeat !== SeatNumber;

    if (levelChanged || rowChanged || seatChanged) {
      params.row.status = "Changed";
      setIsAnyRowChanged(true);
      return <CancelIcon style={{ color: "red" }} />;
    }
    else {
      params.row.status = "unChanged";

      if (rowDetails.some((row) => row.status === "Changed")) {
        setIsAnyRowChanged(true);
      } else {
        setIsAnyRowChanged(false);
      }

      return <CheckCircleIcon style={{ color: "green" }} />;
    }
  };

  const renderFaceValueCell = (params) => {
    const id = params.id;
    const faceValue = faceValueStatus[id] !== undefined ? faceValueStatus[id] : true; // Default is CheckIcon (true)

    const handleClick = () => {
      setFaceValueStatus((prevStatus) => {
        return {
          ...prevStatus,
          [id]: !faceValue,
        };
      });
    };

    return faceValue ? (
      <CheckIcon color="success" onClick={canEditOrder ? handleClick : null} style={{ cursor: 'pointer' }} />
    ) : (
      <CloseIcon color="error" onClick={canEditOrder ? handleClick : null} style={{ cursor: 'pointer' }} />
    );
  };

  // Single refund button
  const renderRefundButton = (params) => {
    const handleRefundClick = async () => {
      try {

        const id = params.id;
        const face_value = faceValueStatus[id] !== undefined ? faceValueStatus[id] : true;

        const service_charge = face_value ? 0 : params.row.service_charge;
        const response = await apiClient.post(`${process.env.REACT_APP_API_URL}/v1/order/singlerefund`,
          {
            order_number: params.row.order_number,
            seat_id: params.row.id,
            price: params.row.Price,
            service_charge: service_charge
          });

        if (response.status === 200) {
          alert("Refund successful.");
          // Refresh the data, if needed.
        } else {
          alert("Refund failed.");
        }
      } catch (error) {
        console.error(error);
        alert("Refund failed.");
      }
      finally {
        await fetchRowDetails();
      }
    };

    const isChanged = params.row.status === "Changed";

    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={handleRefundClick}
        disabled={isChanged || !canEditOrder}
      >
        Refund
      </Button>
    );
  };

  const orderDetailsColumns = [
    {
      field: "id", headerName: "ID", minWidth: 50
    },
    {
      field: "full_name", headerName: "Full Name", minWidth: 50,  valueGetter: (params) =>
        `${params.row.bill_first_name} ${params.row.bill_last_name}`,
    },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "order_number", headerName: "Order Number", minWidth: 50 },
    {
      field: "Price", headerName: "Price", minWidth: 80, flex: 1,  valueGetter: (params) =>
        MakeCurrency(params.row.Price)
    },

    { field: "Level", headerName: "Level", flex: 1, minWidth: 200, maxWidth: 250, renderCell: renderLevelCell },
    { field: "Row", headerName: "Row", maxWidth: 100, renderCell: renderRowCell },
    { field: "SeatNumber", headerName: "Seat", maxWidth: 100, renderCell: renderSeatCell },
    { field: "Barcode", headerName: "Barcode", minWidth: 200,  flex: 1 },
  ];

  if (canEditOrder) {
    orderDetailsColumns.push(
      {
        field: "service_charge", headerName: "Service fee", minWidth: 80, editable: canEditOrder, flex: 1,  valueGetter: (params) =>
          MakeCurrency(params.row.service_charge)
      },

      { field: "IsAttended", headerName: "Scanned", minWidth: 30 },
      { field: "status", headerName: "Unchanged", minWidth: 30, renderCell: renderStatusCell },
      { field: "face_value", headerName: "Face Value", minWidth: 100, renderCell: renderFaceValueCell },
      { field: "refund", headerName: "Refund", minWidth: 150, sortable: false, filterable: false, renderCell: renderRefundButton, },
    );
  }

  const paypalInfoColumns = [
    { field: "Field", headerName: "Field", flex: 1 },
    { field: "Value", headerName: "Value", flex: 1 },
  ]


  // Handle refund all button
  const handleRefundAll = async (rowDetails) => {
    try {

      const response = await apiClient.post(`${process.env.REACT_APP_API_URL}/v1/order/fullrefund`,
        {
          order_number: rowDetails[0].order_number,
          event_number: selectedEventNumber,
        });

      if (response.status === 200) {
        alert("Refund successful.");
        // Refresh the data, if needed.
      } else {
        alert("Refund failed.");
      }
    } catch (error) {
      console.error(error);
      alert("Refund failed.");
    }
    finally {
      await fetchRowDetails();
    }
  };

  // Handle save all button
  const handleSaveAll = async (rowDetails) => {
    try {
      for (const row of rowDetails) {
        if (row.status && row.status === "Changed") {
          const response = await apiClient.post(`${process.env.REACT_APP_API_URL}/v1/order/update`, {
            order_number: row.order_number,
            seat_id: row.id,
            level: row.Level,
            row: row.Row,
            seat: row.SeatNumber,
            event_number: selectedEventNumber,
          });

          if (response.status !== 200) {
            console.error(`Order update failed for order_number ${row.order_number}`);
          }
        }
      }
      alert("Order updates processed.");

    } catch (error) {
      console.error(error);
      alert("Order update failed.");
    } finally {
      await fetchRowDetails();
    }
  };


  // Handle resend email button
  const handleResendEmail = async (rowDetails) => {
    try {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': apiKey,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
      };

      // Directly create the data object with the order number as an array
      const data = {
        order_numbers: [rowDetails[0].order_number],
      };

      const response = await axios.post(`https://api.persiantix.com/ticket/v1/email`, data, { headers: headers });

      if (response.status === 200) {
        console.log('Sending email, successfully.');
        alert("Sending email, successfully!");
      } else {
        alert("Error Sending email!");
      }
    }
    catch (error) {
      console.error('Error Sending email!', error);
      alert("Error Sending email!");
    }
  };

  // Handle resend email button
  const handlePaypalInfo = async (rowDetails) => {
    // Fetch the API response here, assuming the response contains an array of data
    // Replace this with your actual API call
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': apiKey,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
    };

    // Directly create the data object with the order number as an array
    const data = {
      order_number: rowDetails[0].order_number,
    };

    try {
      const response = await axios.post(`https://api.persiantix.com/report/v1/firestore`, data, { headers: headers });

      if (response.status === 200) {
        console.log('Firestore Fetch, successfully.');
      } else {
        alert("Error Firestore Fetch!");
        return;
      }

      // Update the state with the fetched data and display the popup
      if (response.data.message && response.data.message.length >= 0) {
        setPaypalInfoData(
          response.data.message.map((item, index) => ({
            ...item,
            id: index,
          }))
        );
      }
      setPaypalInfoPopup(true);
    }
    catch (error) {
      console.error('Error Firestore Fetch!', error);
      alert("Error Firestore Fetch!");
    }
  };

  // Handle first name change
  const handleFirstNameChange = (event) => {

    const newFirstName = event.target.value;
    setFirstName(newFirstName);
    setFirstNameStatus("editing");

  };

  // Handle first name blur
  const handleFirstNameBlur = (event) => {

    const newFirstName = event.target.value;

    setFirstName(newFirstName);
    setFirstNameStatus("editing");

    const updateFirstNameInDB = async (firstName) => {
      try {
        if (rowDetails[0].bill_first_name === newFirstName) {
          setFirstNameStatus("success");
          return;
        }
        const response = await apiClient.put(`${process.env.REACT_APP_API_URL}/v1/customer/firstname`, {
          order_number: rowDetails[0].order_number,
          first_name: firstName,
          event_number: selectedEventNumber,
        });

        if (response.status === 200) {
          setFirstNameStatus("success");
          console.log('Full name updated successfully.');
        } else {
          console.error('Error updating full name.');
        }
      } catch (error) {
        console.error('Error updating full name:', error);
      }
    };

    updateFirstNameInDB(newFirstName);
  };

  // Handle last name change
  const handleLastNameChange = (event) => {

    const newLastName = event.target.value;
    setLastName(newLastName);
    setLastNameStatus("editing");

  };

  // Handle last name blur
  const handleLastNameBlur = (event) => {

    const newLastName = event.target.value;

    setLastName(newLastName);
    setLastNameStatus("editing");

    const updateLastNameInDB = async (lastName) => {
      try {
        if (rowDetails[0].bill_last_name === newLastName) {
          setLastNameStatus("success");
          return;
        }
        const response = await apiClient.put(`${process.env.REACT_APP_API_URL}/v1/customer/lastname`, {
          order_number: rowDetails[0].order_number,
          last_name: lastName,
          event_number: selectedEventNumber,
        });

        if (response.status === 200) {
          setLastNameStatus("success");
          console.log('Full name updated successfully.');
        } else {
          console.error('Error updating full name.');
        }
      } catch (error) {
        console.error('Error updating full name:', error);
      }
    };

    updateLastNameInDB(newLastName);
  };

  // Store the first name and last name in the state
  useEffect(() => {
    if (rowDetails.length > 0) {
      setFirstName(rowDetails[0].bill_first_name);
      setLastName(rowDetails[0].bill_last_name);
    }
  }, [rowDetails, selectedOrderNumber]);


  // Format phone number
  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  function MakeCurrency(val) {
    if (!val) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0.00)
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val.toFixed(2))
  }

  return (
    <div className="dashboard">
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="lg">
        <DialogTitle>Order Details
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {rowDetails.length > 0 && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
                <Box sx={{ display: 'flex' }}>
                  <TextField
                    label="First Name"
                    value={firstName}
                    onChange={canEditOrder ? handleFirstNameChange : null}
                    onBlur={canEditOrder ? handleFirstNameBlur : null}
                    sx={{
                      marginBottom: "1rem",
                      borderColor: firstNameStatus === "editing" ? "error.main" : firstNameStatus === "success" ? "success.main" : "grey.500",
                      marginRight: "1rem",
                      flexGrow: 1,
                    }}
                    InputProps={{
                      readOnly: !canEditOrder,
                      //focused: true,
                      error: firstNameStatus === "editing",
                    }}
                  />
                  <TextField
                    label="Last Name"
                    value={lastName}
                    onChange={canEditOrder ? handleLastNameChange : null}
                    onBlur={canEditOrder ? handleLastNameBlur : null}
                    sx={{
                      marginBottom: "1rem",
                      borderColor: lastNameStatus === "editing" ? "error.main" : lastNameStatus === "success" ? "success.main" : "grey.500",
                      flexGrow: 1,
                    }}
                    InputProps={{
                      readOnly: !canEditOrder,
                      //focused: true,
                      error: lastNameStatus === "editing",
                    }}
                  />
                </Box>
                <Box sx={{ display: 'flex' }}>
                {canViewInternalFields && (
                  <>
                  <ThemeProvider theme={customTheme}>
                    <TextField
                      label="Email"
                      value={rowDetails[0].email}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        marginBottom: '1rem',
                        marginRight: "1rem",
                        flexGrow: 1,
                      }}
                    />
                  </ThemeProvider>
                  <ThemeProvider theme={customTheme}>
                    <TextField
                      label="Phone"
                      value={formatPhoneNumber(rowDetails[0].phone)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        marginBottom: '1rem',
                        flexGrow: 1,
                      }}
                    />
                  </ThemeProvider>
                  </>)}
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <ThemeProvider theme={customTheme}>
                    <TextField
                      label="Order Number/Date"
                      value={`${rowDetails[0].order_number} --- ${new Date(rowDetails[0].order_number.toString().substring(0, 10) * 1000).toISOString().slice(0, 10)}`}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ marginBottom: '1rem', marginRight: "1rem", flexGrow: 1 }}
                    />
                  </ThemeProvider>
                  <ThemeProvider theme={customTheme}>
                    <TextField
                      label="Event Name"
                      value={`${rowDetails[0].event_name} - ${rowDetails[0].event_date}`}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ marginBottom: '1rem', flexGrow: 1 }}
                    />
                  </ThemeProvider>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  {canViewInternalFields && (
                    <><ThemeProvider theme={customTheme}>
                      <TextField
                        label="Status"
                        value={rowDetails[0].submitted === "yes" ? "Sold" : rowDetails[0].submitted === "no" ? "Not Sold" : toTitleCase(rowDetails[0].submitted)}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="start">
                              {rowDetails[0].submitted === "yes" ? (
                                <CheckIcon color="success" />
                              ) : rowDetails[0].submitted === "no" ? (
                                <CloseIcon color="error" />
                              ) : (
                                <WarningIcon color="warning" />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        sx={{ marginBottom: '1rem', marginRight: "1rem", flexGrow: 1 }}
                      />
                    </ThemeProvider>
                      <ThemeProvider theme={customTheme}>
                        <TextField
                          label="Gross"
                          value={MakeCurrency(rowDetails[0].total_price)}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{ marginBottom: '1rem', marginRight: "1rem", flexGrow: 1 }}
                        />
                      </ThemeProvider>
                    </>)}
                  <ThemeProvider theme={customTheme}>
                    <TextField
                      label="Face Value"
                      value={MakeCurrency(rowDetails[0].total_srv_free)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ marginBottom: '1rem', marginRight: "1rem", flexGrow: 1 }}
                    />
                  </ThemeProvider>
                  {canViewInternalFields && (
                    <><ThemeProvider theme={customTheme}>
                      <TextField
                        label="Service Fee"
                        value={MakeCurrency(summary.totalServiceCharge)}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{ marginBottom: '1rem', marginRight: "1rem", flexGrow: 1 }}
                      />
                    </ThemeProvider>
                      <ThemeProvider theme={customTheme}>
                        <TextField
                          label="MC Fee"
                          value={MakeCurrency(rowDetails[0].mc_fee)}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{ marginBottom: '1rem', marginRight: "1rem", flexGrow: 1 }}
                        />
                      </ThemeProvider>
                      <ThemeProvider theme={customTheme}>
                        <TextField
                          label="Refunded Fee"
                          value={MakeCurrency(rowDetails[0].refunded_fee)}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{ marginBottom: '1rem', marginRight: "1rem", flexGrow: 1 }}
                        />
                      </ThemeProvider>
                      <ThemeProvider theme={customTheme}>
                        <TextField
                          label="Event No"
                          value={rowDetails[0].event_number}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{ marginBottom: '1rem', marginRight: "1rem", flexGrow: 1 }}
                        />
                      </ThemeProvider>                      
                    </>)}
                  <ThemeProvider theme={customTheme}>
                    <TextField
                      label="Quantity"
                      value={rowDetails[0].quantity}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ marginBottom: '1rem', flexGrow: 1 }}
                    />
                  </ThemeProvider>
                </Box>
              </Box>
            </>
          )}
          <Box height={500} width="100%">
            <ThemeProvider theme={dataGridTheme}>
              <DataGrid
                columnVisibilityModel={{
                  // Hide columns status and traderName, the other columns will remain visible
                  full_name: false,
                  email: false,
                  order_number: false,
                }}
                rows={rowDetails}
                columns={orderDetailsColumns}
                pageSize={11}
                disableColumnMenu
                disableSelectionOnClick
              />
            </ThemeProvider>
            <Box sx={{ marginTop: '1rem', marginLeft: '1rem' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} sx={{ backgroundColor: (theme) => theme.palette.primary.main, borderRadius: 1, p: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
                    Face Value: <span sx={{ fontWeight: 'normal' }}>{MakeCurrency(summary.totalPrice)}</span>
                  </Typography>
                </Grid>
                {canViewInternalFields && (<><Grid item xs={12} sm={4} sx={{ backgroundColor: (theme) => theme.palette.primary.main, borderRadius: 1, p: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
                    Total Service Charge: <span sx={{ fontWeight: 'normal' }}>{MakeCurrency(summary.totalServiceCharge)}</span>
                  </Typography>
                </Grid></>)}
                <Grid item xs={12} sm={4} sx={{ backgroundColor: (theme) => theme.palette.primary.main, borderRadius: 1, p: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
                    Total Rows: <span sx={{ fontWeight: 'normal' }}>{summary.totalRows}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        {canEditOrder && (<><DialogActions>
          <Button onClick={() => handleRefundAll(rowDetails)} disabled={!canEditOrder} >Refund All</Button>
          <Button onClick={() => handleSaveAll(rowDetails)} disabled={!isAnyRowChanged || !canEditOrder}>Save All</Button>
          <Button onClick={() => handleResendEmail(rowDetails)} disabled={isAnyRowChanged || !canEditOrder} >Re-send Email</Button>
          <Button onClick={() => handlePaypalInfo(rowDetails)} disabled={isAnyRowChanged || !canEditOrder}>Paypal Info</Button>
          {/* <Button onClick={handleCloseDialog}>Close</Button> */}
        </DialogActions></>)}
      </Dialog>
      <Dialog open={paypalInfoPopup} onClose={() => setPaypalInfoPopup(false)} fullWidth maxWidth="md">
        <DialogTitle>
          Paypal Info
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setPaypalInfoPopup(false)}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box height={500} width="100%">
            <ThemeProvider theme={dataGridTheme}>
              <DataGrid
                rows={paypalInfoData}
                columns={paypalInfoColumns} // Define the columns for the Paypal info grid
                pageSize={11}
                disableColumnMenu
                disableSelectionOnClick
              />
            </ThemeProvider>
          </Box>
        </DialogContent>
      </Dialog>

    </div>
  );
}


export default withPermissions(OrderDetails, OrderDetailsPermissions);