import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OrderDetails from "./Orders/OrderDetails";
import EventSelector from "./Events/EventSelector";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from "@mui/material/MenuItem";
import "./Orders/Orders.css";
import InputMask from 'react-input-mask';
import axios from "axios";
import withPermissions from "./withPermissions";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LevelRowSeats from "./Orders/LevelRowSeats"; // import the new component
import { useApiKey } from "./contexts/ApiKeyContext";
import ApiService from './Api/ApiService';

const OrdersPanelPermissions = [
    "orders.view_all",
    ]

function OrdersPanel(props) {
    // const userPermissions  = props.userPermissions;
    // const userEvents = props.userEvents;
    const navigate = useNavigate();
    const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
    const [searchOrderNumber, setSearchOrderNumber] = useState("");
    const [showNewOrderForm, setShowNewOrderForm] = useState(false);
    const [showActiveEvents, setShowActiveEvents] = useState(true);
    const [selectedEventNumber, setSelectedEventNumber] = useState(null);
    const [selectedEventName, setSelectedEventName] = useState(null);
    const [isComplementary, setIsComplementary] = useState(false);
    const [isCOD, setIsCOD] = useState(false);
    const [levelRowSeat, setLevelRowSeat] = useState({});
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const { apiKey, userPermissions, userEvents } = useApiKey();

    const [levelRowSeatsList, setLevelRowSeatsList] = useState([{ level: "", row: "", seats: "" }]);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        description: "",
        isComplementary: false,
        isCOD: false,
    });
    const [message, setMessage] = useState({ type: "", text: "" });

    const apiClient = ApiService();

    const canCreateOrders = userPermissions.includes("orders.create");

    const handleLevelRowSeatsChange = (index, level, row, seats) => {
        const updatedLevelRowSeatsList = [...levelRowSeatsList];
        updatedLevelRowSeatsList[index] = { level, row, seats };
        setLevelRowSeatsList(updatedLevelRowSeatsList);
    };

    const handleAddLevelRowSeats = () => {
        setLevelRowSeatsList([...levelRowSeatsList, { level: "", row: "", seats: "" }]);
    };

    const handleRemoveLevelRowSeats = (index) => {
        setLevelRowSeatsList(levelRowSeatsList.filter((_, i) => i !== index));
    };


    function PhoneNumberField() {
        return (
            <Grid item xs={6}>
                <InputMask mask="(999) 999-9999" maskChar=" ">
                    {() => <TextField fullWidth label="Phone Number" required />}
                </InputMask>
            </Grid>
        );
    }

    const handleNewOrderClick = () => {
        setShowNewOrderForm(true);
        resetForm();
        setLevelRowSeatsList([{ level: "", row: "", seats: "" }]);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Collect form field values
        const orderData = {
            firstname: formData.firstName,
            lastname: formData.lastName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            description: formData.description,
            isComplementary: formData.isComplementary,
            isCOD: formData.isCOD,
            levelRowSeats: levelRowSeatsList,
            event_number: selectedEventNumber // Assuming you want to include this in the API call
        };

        if (!selectedEventNumber || selectedEventNumber <= 0) {
            setMessage({ type: "error", text: "Please select an event." });
            return;
        }

        const isInvalidLevelRowSeat = orderData.levelRowSeats.some(({ level, row, seats }) => (
            !level || !level.trim() || !row || !row.trim() || !seats || !seats.trim()
        ));

        if (isInvalidLevelRowSeat) {
            setMessage({ type: "error", text: "Level, row, or seats value is empty or contains only whitespace." });
            return;
        }

        // Set loading to true
        setLoading(true);

        // Make the API call
        try {
            const response = await apiClient.post(`/v1/order/add`, orderData);
            if (response.status === 200) {
                setMessage({ type: "success", text: "Order submitted successfully." });
                setSelectedOrderNumber(response.data.order_number);
                setOpenDialog(true);
                setLoading(false);
            } else {
                setLoading(false);
                setMessage({ type: "error", text: response.data.message });
            }
        } catch (error) {
            setLoading(false);
            setMessage({ type: "error", text: "Order submission failed." });
            console.error("Error submitting order:", error);
        }
    };


    const handleBackButtonClick = () => {
        // Navigate back to the App page
        navigate(-1);
    };

    const handleSearchButtonClick = () => {
        setSelectedOrderNumber(searchOrderNumber);
        setOpenDialog(true);
    };

    const handleShowActiveEventsChange = (e) => {
        setShowActiveEvents(e.target.checked);
    };

    const handleEventChange = (e) => {
        setSelectedEventNumber(e.target.value);
        setSelectedEventName(e.target.options[e.target.selectedIndex].dataset.eventName);
    };

    const handleIsComplementaryChange = (e) => {
        setIsComplementary(e.target.checked);
        setFormData({ ...formData, isComplementary: e.target.checked });
    };

    const handleIsCODChange = (e) => {
        setIsCOD(e.target.checked);
        setFormData({ ...formData, isCOD: e.target.checked });
    };

    // Set all Level Row Seat
    useEffect(() => {
        async function fetchLevels() {
            try {
                if (selectedEventNumber == null || selectedEventNumber <= 0) return;

                const levelrowseat = {};

                const response = await apiClient.get(`/v1/event/levelrowseat?event_number=${selectedEventNumber}`);
                for (const data of response.data) {

                    if (!levelrowseat[data.Level]) {
                        levelrowseat[data.Level] = {};
                    }
                    if (!levelrowseat[data.Level][data.Row]) {
                        levelrowseat[data.Level][data.Row] = {};
                    }
                    levelrowseat[data.Level][data.Row] = data.Seats.split(",").map((seat) => seat.trim());
                }

                setLevelRowSeat(levelrowseat);
            } catch (error) {
                console.error("Error fetching Levels:", error);
            }
        }

        fetchLevels();
    }, [selectedEventNumber]);

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     // Your form submission logic here
    // };

    const resetForm = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            description: '',
            isComplementary: false,
            isCOD: false
        });
        setLevelRowSeatsList([{ level: '', row: '', seats: '' }]);
        //setSelectedEventNumber(null);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            {/* <IconButton
                onClick={handleBackButtonClick}
                color="primary"
                sx={{
                    position: "absolute",
                    left: 8,
                    top: 8,
                }}
            >
                <ArrowBackIcon />
            </IconButton> */}
            <h1 style={{ fontSize: "2rem", margin: "3rem 0" }}>Order Panel</h1>
            <Box component={Paper} p={2} borderRadius={4} mb={4}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Order Number"
                            value={searchOrderNumber}
                            onChange={(e) => setSearchOrderNumber(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSearchButtonClick}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            {/* <Button onClick={handleNewOrderClick}>New Order</Button> */}
            <button className="new-order-button" disabled={!canCreateOrders} onClick={canCreateOrders ? handleNewOrderClick : ''}>
                NEW ORDER
            </button>
            {showNewOrderForm && (
                <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 2, maxWidth: '1000px', margin: '0 auto' }}>
                    <EventSelector
                        showActiveEvents={showActiveEvents}
                        handleShowActiveEventsChange={handleShowActiveEventsChange}
                        handleEventChange={handleEventChange}
                        userEvents={userEvents}
                        userPermissions={userPermissions}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="First Name"
                                required
                                value={formData.firstName}
                                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                required
                                value={formData.lastName}
                                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                required
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputMask
                                mask="(999) 999-9999"
                                maskChar=" "
                                value={formData.phoneNumber}
                                onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                            >
                                {() => <TextField fullWidth label="Phone Number" />}
                            </InputMask>

                            {/* <PhoneInput
                    international
                    placeholder="Enter your phone number"
                    value={value}
                    onChange={setValue}
                    /> */}
                        </Grid>
                        {levelRowSeatsList.map((_, index) => (
                            <React.Fragment key={index}>
                                <Grid container item spacing={2}>
                                    <LevelRowSeats
                                        levelRowSeat={levelRowSeat}
                                        level={levelRowSeatsList[index].level}
                                        row={levelRowSeatsList[index].row}
                                        seats={levelRowSeatsList[index].seats}
                                        onLevelRowSeatsChange={(level, row, seats) =>
                                            handleLevelRowSeatsChange(index, level, row, seats)
                                        }
                                    />
                                    {index > 0 && (
                                        <Grid item xs={0}>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleRemoveLevelRowSeats(index)}
                                            >
                                                <RemoveCircleOutlineIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                                    {index === levelRowSeatsList.length - 1 && (
                                        <Grid item xs={0}>
                                            <IconButton color="primary" onClick={handleAddLevelRowSeats}>
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                            </React.Fragment>
                        ))}
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Description"
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <div className="complementaryContainer">
                                <label htmlFor="complementary" className="complementaryLabel">
                                    <span className="switch">
                                        <input
                                            type="checkbox"
                                            name="complementary"
                                            id="complementary"
                                            checked={isComplementary}
                                            onChange={handleIsComplementaryChange}
                                            disabled={isCOD} // Disables if isCOD is true
                                        />
                                        <span className="slider round"></span>
                                    </span>
                                    Complementary
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="codContainer">
                                <label htmlFor="cod" className="codLabel">
                                    <span className="switch">
                                        <input
                                            type="checkbox"
                                            name="cod"
                                            id="cod"
                                            checked={isCOD}
                                            onChange={handleIsCODChange}
                                            disabled={isComplementary} // Disables if isComplementary is true
                                        />
                                        <span className="slider round"></span>
                                    </span>
                                    COD
                                </label>
                            </div>
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={!selectedEventNumber}
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                    px: 4,
                                    py: 1,
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    borderRadius: '25px',
                                    boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.3)',
                                }}
                            //onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        )}
                    </Box>
                    {message.text && (
                        <p className={`message ${message.type}`}>
                            {message.text}
                        </p>
                    )}
                </Box>
            )}
            {selectedOrderNumber && (
                <OrderDetails
                    order_number={selectedOrderNumber}
                    handleCloseDialog={handleCloseDialog}
                    openDialog={openDialog}
                    userPermissions={userPermissions}
                />
            )}
        </div>
    );
}

export default withPermissions(OrdersPanel, OrdersPanelPermissions);