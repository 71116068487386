import React, { useState, useEffect, useCallback, useContext } from 'react';
import './SeatingCharts/Tooltip.css';
import axios from "axios";

import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventSelector from "./Events/EventSelector";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SeatMapModal from './SeatingCharts/SeatMapModal'
import withPermissions from "./withPermissions";
import OrderDetails from "./Orders/OrderDetails";
import { useApiKey } from "./contexts/ApiKeyContext";
import ApiService from './Api/ApiService';

const SeatingChartPermissions = ["seatingChart.view",];

const SeatingChartPanel = (props) => {
  // const userPermissions = props.userPermissions;
  // const userEvents = props.userEvents;
  const navigate = useNavigate();
  const [htmlContent, setHtmlContent] = useState('');
  const [selectedRadioButton, setSelectedRadioButton] = useState(null);
  const [priceCounts, setPriceCounts] = useState({});
  const [selectedPriceCard, setSelectedPriceCard] = useState(null);
  const [selectedSoldCard, setSelectedSoldCard] = useState(null);
  const [venueCol, setVenueCol] = useState(0);
  const selectedButtons = { first: null, second: null, redio: null };
  const [selectedEventNumber, setSelectedEventNumber] = useState(null);
  const [seatClassName, setSeatClassName] = useState(null);
  const [featuredSeats, setFeaturedSeats] = useState(null);
  const [selectedAttributeCard, setSelectedAttributeCard] = useState(null);
  const [showActiveEvents, setShowActiveEvents] = useState(true);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [searchClickedOrderNumber, setSearchClickedOrderNumber] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [validPrices, setValidPrices] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const { apiKey, userPermissions, userEvents } = useApiKey();
  const apiClient = ApiService();
  let selectedButtonsPrevColor = '';

  const handleSearchOrderNumberChange = (orderNumber) => {

    try {
      // const response = await apiClient.get(`/v1/order/details?order_number=${searchOrderNumber}`);

      // if (response && response.data && response.data[0].event_number > 0) {
      //   const buttons = document.querySelectorAll(`input.${seatClassName}`);
      //   const cellIds = response.data.map((value) => (value.id));

      const allButtons = document.querySelectorAll(`input.${seatClassName}`);
      const selectedSeats = document.querySelectorAll(`input.${seatClassName}[data-ordernumber="${orderNumber}"]`);

      // Use a Set for faster lookup
      const cellIds = new Set(Array.from(selectedSeats).map((value) => Number(value.getAttribute('id'))));

      allButtons.forEach((button) => {
        const id = Number(button.getAttribute('id'));

        // Use ternary operator for better readability
        button.style.opacity = cellIds.has(id) ? '1' : '0.1';
      });


    } catch (e) {
      console.log(e);
    }
  }

  const handleSearchButtonClick = async () => {
    setSelectedOrderNumber(searchOrderNumber);
    try {
      // const response = await apiClient.get(`/v1/order/details?order_number=${searchOrderNumber}`);

      // if (response && response.data && response.data[0].event_number > 0) {
      //   const buttons = document.querySelectorAll(`input.${seatClassName}`);
      //   const cellIds = response.data.map((value) => (value.id));

      handleSearchOrderNumberChange(searchOrderNumber)
    } catch (e) {
      console.log(e);
    }
  };

  const handleShowActiveEventsChange = (e) => {
    setShowActiveEvents(e.target.checked);
  };
  ;

  //handle event change
  const handleEventChange = (event) => {
    setPriceCounts({});
    setSelectedPriceCard(null);
    setHtmlContent('');
    setSeatClassName(null);
    setFeaturedSeats(null);
    setSelectedAttributeCard(null);
    setModalVisible(false);
    setValidPrices({});
    setSelectedEventNumber(event.target.value);
  };


  const handleRadioButtonChange = useCallback((e) => {
    setSelectedRadioButton(e.target.value);
  });

  // Handle seat selection click
  const handleInputButtonClick = useCallback((e, id) => {

    if (!selectedButtons.first) {
      selectedButtons.first = id;
    }
    else if (selectedButtons.first && !selectedButtons.second) {
      selectedButtons.second = id;
    }

    if (selectedButtons.first && !selectedButtons.second) {
      selectedButtonsPrevColor = document.getElementById(selectedButtons.first).style.backgroundColor;
      document.getElementById(selectedButtons.first).style.backgroundColor = 'yellow';
    }

    if (selectedButtons.first && selectedButtons.second) {
      //const radioButtons = document.querySelectorAll('input[type="radio"]');

      const buttons = document.querySelectorAll(`input.${seatClassName}`);
      const cellIds = getCellIdsInSquare(selectedButtons.first, selectedButtons.second, venueCol);

      buttons.forEach((button) => {
        const id = parseFloat(button.getAttribute('id'));

        if (cellIds.includes(id)) {
          button.style.opacity = '1';
        } else {
          button.style.opacity = '0.1';
        }
      });

      setModalVisible(true);

      // for (let i = 0; i < radioButtons.length; i++) {
      //   if (radioButtons[i].checked) {
      //     selectedButtons.redio = radioButtons[i].value;
      //   }
      // }

      // if (selectedButtons.redio) {
      //   apiCall(selectedButtons.first, selectedButtons.second, selectedButtons.redio);
      // }

      document.getElementById(selectedButtons.first).style.backgroundColor = selectedButtonsPrevColor;
      selectedButtons.first = null;
      selectedButtons.second = null;
      selectedButtons.redio = null;
    }
  });

  // Fetch the HTML content for event
  const fetchHtmlContent = async () => {
    try {
      if (!selectedEventNumber) {
        return;
      }
      const targetUrl = `https://persiantix.com/php/getusertest.php?event=${selectedEventNumber}`;
      const response = await fetch(targetUrl);
      const html = await response.text();

      // const response = await apiClient.get(`/v1/charts?event_number=${selectedEventNumber}`);
      // const html = response.data.content;

      setHtmlContent(html);
      window.tooltip = { show: () => { }, hide: () => { } };
    } catch (err) {
      console.error('Error fetching HTML content:', err.message);
    }
  };

  useEffect(() => {
    const fetchVenueColLength = async () => {
      try {
        if (!selectedEventNumber || parseInt(selectedEventNumber) <= 0) {
          return;
        }
        const response = await apiClient.get(`/v1/venue/col?event_number=${selectedEventNumber}`);
        setVenueCol(response.data[0].Col);

        const resFeature = await apiClient.get(`/v1/event/seats/features?event_number=${selectedEventNumber}`);
        setFeaturedSeats(resFeature.data);

        const eventPrices_response = await apiClient.get(`/v1/event/prices?event_number=${selectedEventNumber}`);
        setValidPrices(eventPrices_response.data);

        fetchHtmlContent()

      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchVenueColLength();
  }, [selectedEventNumber]);

  // useEffect(() => {
  //   fetchHtmlContent();
  // }, [selectedEventNumber]);

  const updateTooltipPosition = (e) => {
    const tooltip = document.getElementById('custom-tooltip');
    const yOffset = window.pageYOffset || document.documentElement.scrollTop;
    tooltip.style.top = e.clientY - 50 + yOffset + 'px';
    tooltip.style.left = e.clientX + 20 + 'px';
  };

  //Handle seat double click
  const handleInputButtonDoubleClick = (event, order_number) => {

    if (!order_number) {
      return;
    }

    setSearchClickedOrderNumber(order_number);
    handleSearchOrderNumberChange(order_number);
    setOpenDialog(true);
  };

  //Handle html content and add tooltip 
  useEffect(() => {
    if (htmlContent) {
      const inputs = document.querySelectorAll("input");

      if (inputs) {
        const firstInputWithOnclick = Array.from(inputs).find(
          (input) =>
            input.onclick && input.onclick.toString().includes("SeatReserved")
        );

        const className = firstInputWithOnclick ? firstInputWithOnclick.className : null;

        if (className) {
          setSeatClassName(className);
        }

        const refreshButton = document.getElementById('refresh');
        if (refreshButton) {
          refreshButton.removeAttribute('onclick');
          refreshButton.removeAttribute('style');

          refreshButton.classList.add('refresh-button');

          // Add the event listener for the 'click' event
          refreshButton.addEventListener('click', async () => {
            // Replace this with your custom function
            await fetchHtmlContent();
          });
        }

        const buttons = document.querySelectorAll(`input.${seatClassName}`);
        const priceCountsLocal = {};

        if (!featuredSeats || featuredSeats.length === 0) {
          return;
        }

        buttons.forEach((button) => {
          const buttonId = parseInt(button.id);

          let clickTimeout;

          // Find the corresponding featuredSeat, if it exists
          const featuredSeat = featuredSeats.find(fs => fs.id === buttonId);

          if (featuredSeat) {

            button.setAttribute('data-isstoresale', featuredSeat.IsStoreSale ? 'true' : 'false');
            button.setAttribute('data-iswheelseat', featuredSeat.IsWheelSeat ? 'true' : 'false');
            button.setAttribute('data-istechseat', featuredSeat.IsTechSeat ? 'true' : 'false');
            button.setAttribute('data-iscomplementary', featuredSeat.IsComplementary ? 'true' : 'false');
            button.setAttribute('data-isattended', featuredSeat.IsAttended ? 'true' : 'false');
            button.setAttribute('data-issold', featuredSeat.IsSold ? 'true' : 'false');
            button.setAttribute('data-ispending', featuredSeat.IsPending ? 'true' : 'false');
            button.setAttribute('data-isholding', featuredSeat.IsHolding ? 'true' : 'false');

            if (featuredSeat.order_number) {
              button.setAttribute('data-ordernumber', featuredSeat.order_number);
            }

          }

          const onmouseoverAttr = button.getAttribute('onmouseover');

          if (onmouseoverAttr) {
            const tooltipContent = onmouseoverAttr.match(/tooltip\.show\('(.*?)',/)[1];

            const pricePattern = /Price:\s*\$(\d+)/;
            const priceMatch = tooltipContent.match(pricePattern);
            const price = priceMatch ? parseFloat(priceMatch[1]) : null;
            const bgColor = button.style.backgroundColor;

            button.setAttribute('data-price', price);

            if (!priceCountsLocal[price]) {
              priceCountsLocal[price] = 0;
            }

            priceCountsLocal[price]++;

            button.addEventListener('mouseenter', (e) => {
              const tooltip = document.getElementById('custom-tooltip');
              tooltip.innerHTML = tooltipContent;
              updateTooltipPosition(e);
              tooltip.style.display = 'block';
            });

            button.addEventListener('mousemove', (e) => {
              updateTooltipPosition(e);
            });

            button.addEventListener('mouseleave', () => {
              const tooltip = document.getElementById('custom-tooltip');
              tooltip.style.display = 'none';
            });

            window.addEventListener('scroll', () => {
              if (button.matches(':hover')) {
                const event = new MouseEvent('mousemove', {
                  clientX: button.getBoundingClientRect().left,
                  clientY: button.getBoundingClientRect().top,
                });
                updateTooltipPosition(event);
              }
            });

            // Remove the inline event handlers
            button.removeAttribute('onmouseover');
            button.removeAttribute('onmouseout');
            button.removeAttribute('onclick');

            // Add click event listener
            button.addEventListener('click', (e) => {
              // Cancel the single click event if a double click occurs
              if (clickTimeout) {
                clearTimeout(clickTimeout);
              }

              clickTimeout = setTimeout(() => {
                handleInputButtonClick(e, parseInt(button.id));
              }, 250); // 250ms delay to check for double click
            });

            // Add double-click event listener
            button.addEventListener('dblclick', (e) => {
              if (clickTimeout) {
                clearTimeout(clickTimeout);
              }
              handleInputButtonDoubleClick(e, parseInt(featuredSeat.order_number));
            });

          }
          button.removeAttribute('onmouseover');
        });

        if (priceCountsLocal && Object.keys(priceCountsLocal).length > 0) {
          setPriceCounts(priceCountsLocal)
        }
      }
      //setListenersAttached(true);
    }
  }, [htmlContent, handleInputButtonClick]);

  // Handle feature seats update in database
  const apiCall = async (cellIds, buttonValue, price) => {
    try {
      if (!cellIds || cellIds.length === 0 || !selectedEventNumber || parseInt(selectedEventNumber) <= 0) {
        return;
      }

      let url = ''
      let selected_price = 0;

      switch (buttonValue) {
        case 'On/Off':
          url = `${process.env.REACT_APP_API_URL}/v1/event/seats/onoff`;
          break;
        case 'In-store':
          url = `${process.env.REACT_APP_API_URL}/v1/event/seats/instore`;
          break;
        case 'Wheelchair':
          url = `${process.env.REACT_APP_API_URL}/v1/event/seats/wheelchair`;
          break;
        case 'Tech':
          url = `${process.env.REACT_APP_API_URL}/v1/event/seats/tech`;
          break;
        case 'Price':
          url = `${process.env.REACT_APP_API_URL}/v1/event/seats/price`;
          selected_price = price;
          break;
        default:
          return;
      }

      const data = {
        ids: cellIds,
        event_number: selectedEventNumber
      };

      if (selected_price && selected_price > 0) {
        data.price = selected_price;
      }

      const response = await apiClient.post(url, data);

      if (response.status === 200) {
        //alert("Seats Updated successfully.");
        // Refresh the data, if needed.
      } else {
        alert("Seats Update failed.");
      }
    } catch (error) {
      alert("Seats Update failed.");
      console.error("Error udapting seating charts:", error);
    }

    await fetchHtmlContent();

  };

  function getRowAndColumn(id, columns) {
    const row = Math.ceil(id / columns);
    const column = id % columns === 0 ? columns : id % columns;
    return { row, column };
  }

  // Get the IDs of all the cells between the two selected cells
  function getCellIdsInSquare(id1, id2, columns) {
    const cell1 = getRowAndColumn(id1, columns);
    const cell2 = getRowAndColumn(id2, columns);

    const minRow = Math.min(cell1.row, cell2.row);
    const maxRow = Math.max(cell1.row, cell2.row);
    const minColumn = Math.min(cell1.column, cell2.column);
    const maxColumn = Math.max(cell1.column, cell2.column);

    const cellIds = [];

    for (let row = minRow; row <= maxRow; row++) {
      for (let column = minColumn; column <= maxColumn; column++) {
        const id = (row - 1) * columns + column;
        cellIds.push(id);
      }
    }

    return cellIds;
  }

  // Handle price card click
  const highlightButtonsByPrice = (selectedPrice, isSold = null) => {
    const selectedPriceKey = ((selectedPrice !== null ? selectedPrice.toString() : 'null-') + (isSold !== null ? '-' + isSold.toString() : '-null'));

    if (selectedPriceCard === selectedPriceKey || (selectedPrice === null && isSold === null)) {
      // Revert changes if the same price card is clicked
      const buttons = document.querySelectorAll(`input.${seatClassName}`);

      buttons.forEach((button) => {
        button.style.opacity = '1';
      });

      setSelectedPriceCard(null);
    }
    else {
      // If a different price card is clicked, revert previous changes and apply new ones
      const allButtons = document.querySelectorAll(`input.${seatClassName}`);
      let targetButtons;

      if (selectedPrice !== null && isSold !== null) {
        targetButtons = document.querySelectorAll(`input.${seatClassName}[data-price="${selectedPrice}"][data-issold="${isSold}"]`);
      } else if (selectedPrice !== null) {
        targetButtons = document.querySelectorAll(`input.${seatClassName}[data-price="${selectedPrice}"]`);
      } else if (isSold !== null) {
        targetButtons = document.querySelectorAll(`input.${seatClassName}[data-issold="${isSold}"]`);
      }

      allButtons.forEach(button => {
        button.style.opacity = '0.1';
      });

      targetButtons.forEach(button => {
        button.style.opacity = '1';
      });

      setSelectedPriceCard(selectedPriceKey);
    }
  };


  const handleAttribCardClick = (attribute) => {
    if (attribute === selectedAttributeCard) {
      // Revert changes if the same attribute card is clicked
      const buttons = document.querySelectorAll(`input.${seatClassName}`);

      buttons.forEach((button) => {
        button.style.opacity = '1';
      });

      setSelectedAttributeCard(null);
    } else {
      // If a different attribute card is clicked, revert previous changes and apply new ones
      const allButtons = document.querySelectorAll(`input.${seatClassName}`);
      const targetButtons = document.querySelectorAll(`input.${seatClassName}[${attribute}=true]`);

      allButtons.forEach(button => {
        button.style.opacity = '0.1';
      });

      targetButtons.forEach(button => {
        button.style.opacity = '1';
      });

      setSelectedAttributeCard(attribute);
    }
  };


  // Render the price cards (Create a card for each price)
  const renderCounts = () => {
    if (!priceCounts || Object.keys(priceCounts).length === 0) {
      return null;
    }

    const countCards = [];

    const prices = new Set([...Object.keys(priceCounts)]);

    // Convert the Set into an array, sort it, and then iterate through the sorted array
    const sortedPrices = Array.from(prices).sort((a, b) => parseFloat(a) - parseFloat(b));
    const validPricesArray = validPrices.map(obj => obj.Price);

    sortedPrices.forEach(price => {

      const isValidPrice = validPricesArray.includes(parseInt(price));

      const soldButtons = document.querySelectorAll(`input.${seatClassName}[data-price="${price}"][data-issold="true"]`);
      const sold = soldButtons ? soldButtons.length : 0;

      const unSoldButtons = document.querySelectorAll(`input.${seatClassName}[data-price="${price}"][data-issold="false"]`);
      const unsold = unSoldButtons ? unSoldButtons.length : 0;

      const selectClass = selectedPriceCard === price.toString() + '-null' || selectedPriceCard === price.toString() + '-true' || selectedPriceCard === price.toString() + '-false'

      countCards.push(
        <div
          key={price}
          className={`price-card ${selectClass ? 'selected-price-card' : ''} ${!isValidPrice ? !isValidPrice && unsold !== 0 ? 'blinking' : 'invalid-price-card' : ''}`}
          onClick={() => highlightButtonsByPrice(null, null)}
        >
          <div onClick={(event) => event.stopPropagation()}>
            <p
              className={`total-count-text ${selectedPriceCard === price.toString() + '-null' ? 'selected-price-text' : ''}`}
              onClick={() => highlightButtonsByPrice(price)}
            >
              <h3>${price}</h3>
            </p>
            <p
              className={`total-count-text ${selectedPriceCard === price.toString() + '-true' ? 'selected-price-text' : ''}`}
              onClick={() => highlightButtonsByPrice(price, "true")}
            >
              Sold: {sold}
            </p>

            <p
              className={`total-count-text ${selectedPriceCard === price.toString() + '-false' ? 'selected-price-text' : ''}`}
              onClick={() => highlightButtonsByPrice(price, "false")}
            >
              Unsold: {unsold}
            </p>
          </div>
        </div>
      );
    });

    const allSoldButtons = document.querySelectorAll(`input.${seatClassName}[data-issold="true"]`);
    const allUnSoldButtons = document.querySelectorAll(`input.${seatClassName}[data-issold="false"]`);

    const selectAttribClass = selectedPriceCard === 'null--true' || selectedPriceCard === 'null--false'

    // Add a card for total sold and unsold seats
    countCards.push(
      <div
        className={`price-card ${selectAttribClass ? 'selected-price-card' : ''}`}
        key="total"
        onClick={() => highlightButtonsByPrice(null, null)}>

        <h3>Total</h3>

        <div onClick={(event) => event.stopPropagation()}>
          <p
            className={`total-count-text ${selectedPriceCard === 'null--true' ? 'selected-price-text' : ''}`}
            onClick={() => highlightButtonsByPrice(null, "true")}
          >
            Sold: {allSoldButtons.length || 0}
          </p>
          <p
            className={`total-count-text ${selectedPriceCard === 'null--false' ? 'selected-price-text' : ''}`}
            onClick={() => highlightButtonsByPrice(null, "false")}
          >
            Unsold: {allUnSoldButtons.length || 0}
          </p>
        </div>
      </div>
    );

    const attributeLabels = {
      'data-isstoresale': 'Store Sale',
      'data-iswheelseat': 'Wheelchair',
      'data-istechseat': 'Tech Seat',
      'data-iscomplementary': 'Complementary',
      'data-isattended': 'Attended',
      // 'data-issold': 'Sold',
      'data-ispending': 'Pending',
      'data-isholding': 'Hold',
    };

    const attributes = Object.keys(attributeLabels) || [];

    attributes.forEach(attribute => {

      const targetButtons = document.querySelectorAll(`input.${seatClassName}[${attribute}=true]`);
      const targetSeats = targetButtons.length;

      if (targetSeats && targetSeats > 0) {
        countCards.push(
          <div className={`price-card ${selectedAttributeCard === attribute ? 'selected-price-card' : ''}`}
            key={attribute}
            onClick={() => handleAttribCardClick(attribute)}
          >
            <h3>{attributeLabels[attribute] || attribute}</h3>
            <p>Seats: {targetSeats}</p>
          </div>
        );
      }
    });

    return <div className="price-container">{countCards}</div>;
  };

  // For Modal Dialog Close
  const handleModalCloseClick = () => {
    const buttons = document.querySelectorAll(`input.${seatClassName}`);

    buttons.forEach((button) => {
      button.style.opacity = '1';
    });

    setModalVisible(false);
  };

  // For Modal Dialog > Button click
  const handleModalButtonClick = (buttonValue, price) => {
    const buttons = document.querySelectorAll(`input.${seatClassName}`);
    const cellIds = []

    buttons.forEach((button) => {
      if (button.style.opacity === '1') {
        cellIds.push(parseFloat(button.getAttribute('id')))
      }
    });

    buttons.forEach((button) => {
      button.style.opacity = '1';
    });

    apiCall(cellIds, buttonValue, price);
    setModalVisible(false);
  };

  const handleBackButtonClick = () => {
    // Navigate back to the App page
    navigate(-1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      {/* <IconButton
        onClick={handleBackButtonClick}
        color="primary"
        sx={{
          position: 'absolute',
          left: 8,
          top: 8,
        }}
      >
        <ArrowBackIcon />
      </IconButton> */}
      <h1>Seating Chart Panel</h1>

      <EventSelector
        showActiveEvents={showActiveEvents}
        handleShowActiveEventsChange={handleShowActiveEventsChange}
        handleEventChange={handleEventChange}
        userEvents={userEvents}
        userPermissions={userPermissions}
      />
      <Box component={Paper} p={2} borderRadius={4} mb={4} sx={{ mt: 2, mb: 2, maxWidth: '600px', margin: '0 auto' }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Order Number"
              value={searchOrderNumber}
              onChange={(e) => setSearchOrderNumber(e.target.value.trim())}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSearchButtonClick}
              disabled={!searchOrderNumber || !selectedEventNumber}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      {renderCounts()}
      <div className="panel">

        <div className="content-container">
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
      </div>
      <div id="custom-tooltip" className="tooltip" />
      <SeatMapModal userPermissions={userPermissions} visible={modalVisible} onButtonClick={handleModalButtonClick} onClose={handleModalCloseClick} />
      {searchClickedOrderNumber && (
        <OrderDetails
          order_number={searchClickedOrderNumber}
          handleCloseDialog={handleCloseDialog}
          openDialog={openDialog}
          userPermissions={userPermissions}
        />
      )}
    </div>

  );
};

export default withPermissions(SeatingChartPanel, SeatingChartPermissions);
