// LevelRowSeats.js
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

function LevelRowSeats({ levelRowSeat, level, row, seats, onLevelRowSeatsChange }) {

  // Set Level
  const renderLevelCell = () => {
    const handleChange = async (event) => {
      onLevelRowSeatsChange(event.target.value, row, seats);
    };

    return (
      <Select
        value={level}
        onChange={(event) => handleChange(event)}
        required // Add the required attribute here
      >
        {Object.keys(levelRowSeat).map((level) => (
          <MenuItem key={level} value={level}>
            {level}
          </MenuItem>
        ))}
      </Select>
    );
  };

  // Set Row
  const renderRowCell = () => {
    const handleChange = async (event) => {
      onLevelRowSeatsChange(level, event.target.value, seats);
    };

    const rowsForLevel = Object.keys(levelRowSeat[level] || {});

    return (
      <Select
        value={row}
        onChange={(event) => handleChange(event)}
        required // Add the required attribute here
      >
        {rowsForLevel.map((row) => (
          <MenuItem key={row} value={row}>
            {row}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const handleSeatsChange = (event) => {
    onLevelRowSeatsChange(level, row, event.target.value);
  };

  return (
    <>
      <Grid item xs={3} sm={3}>
        <FormControl fullWidth>
          <InputLabel htmlFor="level">Level</InputLabel>
          {renderLevelCell()}
        </FormControl>
      </Grid>

      <Grid item xs={3} sm={3}>
        <FormControl fullWidth>
          <InputLabel htmlFor="row">Row</InputLabel>
          {renderRowCell()}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Seats"
          placeholder="1,2,3..."
          required
          onChange={handleSeatsChange}
        />
      </Grid>
    </>
  );
}

export default LevelRowSeats;
