import React, { useState, useEffect } from "react";

import axios from "axios";

import { useApiKey } from "./contexts/ApiKeyContext";
import ApiService from './Api/ApiService';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Logout from './Logout';
import Dashboard from "./Dashboard";
import ReportsPanel from "./ReportsPanel";
import DailyReport from "./DailyReport";
import SeatingChartPanel from './SeatingChartPanel';
import OrdersPanel from './OrdersPanel';
import UserManagement from './UserManagement';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import EventsPanel from "./EventsPanel";

function AppHeader() {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate("/dashboard");
  };

  return (
    <header>
      <div className="logout">
        <Logout />
      </div>
      <div className="back-button">
        <IconButton onClick={handleBackButtonClick} color="primary">
          <ArrowBackIcon />
        </IconButton>
      </div>
    </header>
  );
}

function Login({ onLoginSuccess }) {
  const { setApiKey, setUserPermissions, setUserEvents } = useApiKey();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [savePassword, setSavePassword] = useState(true);

  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail");
    const savedPassword = localStorage.getItem("savedPassword");
    const apiKey = localStorage.getItem("apiKey");
    const userPermissions = localStorage.getItem("userPermissions");
    const userEvents = localStorage.getItem("userEvents");

    if (savedEmail && savedPassword && apiKey && userPermissions && userEvents) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setApiKey(apiKey);
      setUserPermissions(userPermissions.split(","));
      setUserEvents(userEvents.split(",").map(Number));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/login`, { email, password });

      if (response.status === 200) {

        setApiKey(response.data.api_key);
        const currentTime = new Date().getTime();
        localStorage.setItem("loginTime", currentTime);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("apiKey", response.data.api_key);
        console.log("Authentication successful");

        if (savePassword) {
          localStorage.setItem("savedEmail", email);
          localStorage.setItem("savedPassword", password);
        } else {
          localStorage.removeItem("savedEmail");
          localStorage.removeItem("savedPassword");
        }

        const userPermissions = response.data.permissions.split(",");
        const userEvents = response.data.event_numbers.split(",").map(Number);

        localStorage.setItem("userPermissions", userPermissions);
        localStorage.setItem("userEvents", userEvents);

        setUserPermissions(userPermissions);
        setUserEvents(userEvents);

        onLoginSuccess();
      }
      else {
        console.log("Invalid email or password");
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        console.log("Invalid email or password");
      } else {
        console.log("Error occurred:", err.message);
      }
    }

    setEmail("");
    setPassword("");
  };

  return (
    <div className="login-container">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <label htmlFor="save-password">
          <input
            type="checkbox"
            id="save-password"
            checked={savePassword}
            onChange={(e) => setSavePassword(e.target.checked)}
          />
          Save Password
        </label>
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

function App() {
  const { setApiKey, setUserPermissions, setUserEvents } = useApiKey();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const loginTime = localStorage.getItem("loginTime");
    let isAuthenticatedLocal = JSON.parse(localStorage.getItem("isAuthenticated"));
    const currentTime = new Date().getTime();
    const thirtyMinutes = 30 * 60 * 1000;

    const apiKey = localStorage.getItem("apiKey");
    const userPermissions = localStorage.getItem("userPermissions");
    const userEvents = localStorage.getItem("userEvents");

    userPermissions ? setUserPermissions(userPermissions.split(",")) : (isAuthenticatedLocal = false);
    userEvents ? setUserEvents(userEvents.split(",").map(Number)) : (isAuthenticatedLocal = false);
    apiKey ? setApiKey(apiKey) : (isAuthenticatedLocal = false);

    if (isAuthenticatedLocal && currentTime - loginTime < thirtyMinutes) {
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("loginTime");
      localStorage.removeItem("apiKey");
      localStorage.removeItem("userPermissions");
      localStorage.removeItem("userEvents");
      setIsAuthenticated(false);
    }
  }, []);

  const handleBackButtonClick = () => {
    // Navigate back to the App page
    // navigate('/dashboard');
  };


  return (
    <div >
    <div className="App">
      
      <Router>
        {isAuthenticated && <AppHeader />}
        <Routes>
          <Route
            exact
            path="/"
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login onLoginSuccess={() => setIsAuthenticated(true)} />}
          />
          <Route
            path="/dashboard"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
          />
          <Route
            path="/reports"
            element={isAuthenticated ? <ReportsPanel /> : <Navigate to="/" />}
          />
          <Route
            path="/daily-report"
            element={isAuthenticated ? <DailyReport /> : <Navigate to="/" />}
          />
          <Route
            path="/seating-charts"
            element={isAuthenticated ? <SeatingChartPanel /> : <Navigate to="/" />}
          />
          <Route
            path="/orders"
            element={isAuthenticated ? <OrdersPanel /> : <Navigate to="/" />}
          />
          <Route
            path="/events"
            element={isAuthenticated ? <EventsPanel /> : <Navigate to="/" />}
          />
          <Route
            path="/users"
            element={isAuthenticated ? <UserManagement /> : <Navigate to="/" />}
          />
        </Routes>
      </Router>
    </div>
    </div>
  );
}

export default App;
