// Logout.js
import React from 'react';
import { useApiKey } from './contexts/ApiKeyContext';
import './Logout/Logout.css';

function Logout() {
  const { setApiKey, setUserPermissions, setUserEvents } = useApiKey();

  const handleLogout = () => {
    setApiKey(null);
    setUserPermissions([]);
    setUserEvents([]);

    localStorage.removeItem('apiKey');
    localStorage.removeItem('userPermissions');
    localStorage.removeItem('userEvents');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('loginTime');

    window.location.href = '/';
  };

  return (
    <button onClick={handleLogout} className="logout-button">
      Logout
    </button>
  );
}

export default Logout;
