import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControlLabel,
  List,
  ListItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Grid,
  Typography,
  Switch,
  IconButton,
  makeStyles
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import withPermissions from "./withPermissions";
import { useApiKey } from "./contexts/ApiKeyContext";
import ApiService from './Api/ApiService';
import axios from "axios";
import './Users/UserManagement.css';

const UserManagementPermissions = ["users.view",
  "users.edit",];

function UserManagement(props) {
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState({});
  const [userEvents, setUserEvents] = useState({});
  const [events, setEvents] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserPermissions, setSelectedUserPermissions] = useState([]);
  const [selectedUserEvents, setSelectedUserEvents] = useState([]);
  const [deletingUser, setDeletingUser] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  //const userPermissions = props.userPermissions;
  const navigate = useNavigate();
  const { apiKey } = useApiKey();

  const apiClient = ApiService();

  const openDeleteDialog = (user) => {
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setUserToDelete(null);
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await apiClient.get(`/v1/users`);

      const uniqueUsers = response.data.reduce((acc, user) => {
        if (!acc.some((item) => item.id === user.id)) {
          acc.push(user);
        }
        return acc;
      }, []);

      setUsers(uniqueUsers);

      const userPermissionsObj = response.data.reduce((acc, user) => {
        if (user.permission_ids) {
          acc[user.id] = user.permission_ids.split(',').map(Number);
        } else {
          acc[user.id] = [];
        }
        return acc;
      }, {});

      setUserPermissions(userPermissionsObj);

      const userEventsObj = response.data.reduce((acc, user) => {
        if (user.event_numbers) {
          acc[user.id] = user.event_numbers.split(',').map(Number);
        } else {
          acc[user.id] = [];
        }
        return acc;
      }, {});

      setUserEvents(userEventsObj);

      const response_permissions = await apiClient.get(`/v1/permissions`);
      setPermissions(response_permissions.data);

      const response_event = await apiClient.get(`/v1/events`);

      const modifiedEvents = [
        { event_number: -1, event_name: 'All Events', event_date: '' },
        ...response_event.data,
      ];

      setEvents(modifiedEvents);

    } catch (error) {
      console.error(error);
    }
  };

  const handleAddRemovePermission = async (userId, checked, permission_id) => {
    // Add or remove a permission for a user and update the server/API
    const updatedPermissions = checked
      ? [...userPermissions[userId], permission_id]
      : userPermissions[userId].filter((perm) => perm !== permission_id);

    // Update the local state
    setUserPermissions({
      ...userPermissions,
      [userId]: updatedPermissions,
    });
    setSelectedUserPermissions(updatedPermissions);

    // Make the API call to add or remove the permission
    try {
      if (checked) {

        const data = {
          user_id: userId,
          permission_id: permission_id
        };

        // Add permission
        await apiClient.post(`${process.env.REACT_APP_API_URL}/v1/user/permission`, data);

      } else {
        const data = {
          user_id: userId,
          permission_id: permission_id
        };
        // Remove permission
        await apiClient.put(`${process.env.REACT_APP_API_URL}/v1/user/permission`, data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddRemoveEvent = async (userId, event_number, checked) => {
    // Add or remove a permission for a user and update the server/API
    const updatedEvents = checked
      ? [...userEvents[userId], event_number]
      : userEvents[userId].filter((perm) => perm !== event_number);

    // Update the local state
    setUserEvents({
      ...userEvents,
      [userId]: updatedEvents,
    });

    setSelectedUserEvents(updatedEvents);

    // Make the API call to add or remove the permission
    try {
      if (checked) {

        const data = {
          user_id: userId,
          event_number: event_number
        };

        // Add permission
        await apiClient.post(`${process.env.REACT_APP_API_URL}/v1/user/event`, data);

      } else {
        const data = {
          user_id: userId,
          event_number: event_number
        };
        // Remove permission
        await apiClient.put(`${process.env.REACT_APP_API_URL}/v1/user/event`, data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateUser = async () => {
    // Create a new user with the provided data and update the server/API
    const useremail = document.getElementById("useremail").value;
    const password = document.getElementById("password").value;

    try {
      const response = await apiClient.post(`${process.env.REACT_APP_API_URL}/v1/users`, {
        useremail,
        password,
      });

      if (response.status === 200) {
        // User created successfully
        // Update the local state and refetch the users list
        fetchUsers();
      }
      else {
        alert("User creation failed");
      }
    } catch (error) {
      alert("User creation failed");
      console.error(error);
    }
  };

  const handleDeleteUser = async (userId) => {
    setDeletingUser(true);
    try {
      const response = await apiClient.delete(`${process.env.REACT_APP_API_URL}/v1/user/${userId}`);
      setUsers(users.filter((user) => user.id !== userId));

      if (response.status === 200) {
        fetchUsers();
      }
    } catch (error) {
      alert("User deletion failed");
      console.error('Error deleting user:', error);
    } finally {
      setDeletingUser(false);
      closeDeleteDialog();
    }
  };


  const handleBackButtonClick = () => {
    // Navigate back to the App page
    navigate(-1);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setSelectedUserPermissions(userPermissions[user.id] || []);
    setSelectedUserEvents(userEvents[user.id] || []);
  };

  return (
    <div className="container">
      {/* <div style={{ display: 'flex', marginTop: '50px' }}>
        <IconButton
          onClick={handleBackButtonClick}
          color="primary"
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
          }}
        >
          <ArrowBackIcon />
        </IconButton>   </div> */}
      <div className="radio-buttons-box">

        <div style={{ display: 'flex', marginBottom: '40px', marginTop: '50px' }}>
          <div className="user-list" style={{ marginRight: '40px' }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Users
            </Typography>
            <div className="userList">
              <List component="nav">
                {users.map((user) => (
                  <ListItem
                    button
                    key={user.id}
                    onClick={() => handleUserClick(user)}
                    className={`userItem ${selectedUser && selectedUser.id === user.id ? "selected" : ""}`}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                      <span>{user.email}</span>
                      <DeleteIcon
                        className="deleteUser"
                        onClick={(e) => {
                          e.stopPropagation();
                          openDeleteDialog(user);
                        }}
                      />

                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
          <div className="permission-list">
            {selectedUser && (
              <div>
                <Typography variant="h4" component="h2" gutterBottom>
                  Permissions
                </Typography>
                <div className="permissionList">
                  {permissions.map((permission) => (
                    <div key={permission.Id} className="formControl">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={selectedUserPermissions.includes(
                              permission.Id
                            )}
                            onChange={(event) =>
                              handleAddRemovePermission(
                                selectedUser.id,
                                event.target.checked,
                                permission.Id
                              )
                            }
                          />
                        }
                        label={permission.permission_name}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="permission-list">
            {selectedUser && (
              <div>
                <Typography variant="h4" component="h2" gutterBottom>
                  Events
                </Typography>
                <div className="eventList">
                  {events.map((m_event) => (
                    <div key={m_event.event_number} className="formControl">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={selectedUserEvents.includes(
                              m_event.event_number
                            )}
                            onChange={(event) =>
                              handleAddRemoveEvent(
                                selectedUser.id,
                                m_event.event_number,
                                event.target.checked
                              )
                            }
                          />
                        }
                        label={`${m_event.event_name}${m_event.event_date ? ` - ${m_event.event_date}` : ''}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="createUser">
        <Typography variant="h4" component="h2" gutterBottom>
          Create New User
        </Typography>
        <TextField id="useremail" label="Email" className="textfield" />
        <TextField id="password" label="Password" type="textfield" className="textfield" />

        <Button onClick={handleCreateUser}>Create User</Button>
      </div>
      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm User Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete user {userToDelete?.email}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteUser(userToDelete?.id)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default withPermissions(UserManagement, UserManagementPermissions);